import { useState, useRef } from "react";
import Train from "../../assets/vectors1/Vector2_x2.svg";
import Down_arrow from "../../assets/vectors1/Down_arrow.svg";
import Reload from "../../assets/vectors1/Reload.svg";
import Arrow_left from "../../assets/vectors1/Arrow_left.svg";
import Arrow_right from "../../assets/vectors1/Arrow_right.svg";

import { getAvailableClassType } from "../../pages/ModesPage";
import { convertToMinutes } from "../../utils/misc";

function TrainIndividual({
  trainComponent,
  fromCity,
  toCity,
  getFares,
  selectedPrice,
  setSelectedPrice,
}) {
  const [selectedClass, setselectedClass] = useState();
  // getAvailableClassType(trainComponent.properties.prices?.general)
  const [quotaOpen, setQuotaOpen] = useState(false);
  const [selectedQuota, setSelectedQuota] = useState("general");

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  // console.log(trainComponent.properties.from_station_name)
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate) => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime) => {
    return timeFormat.format(new Date(inputtime));
  };

  return (
    <div className="train-card">
      <div className="timings">
        <div className="datentime">
          <div className="date">
            <span className="wed-15-may">
              {formatDate(trainComponent.properties.departure_time)}
            </span>
          </div>
          <div className="time">
            <span className="container">
              {formatTime(trainComponent.properties.departure_time)}
            </span>
          </div>
        </div>
        <div className="dotted-line">
          <div className="vector-18-x"></div>
        </div>
        <div className="duration">
          <span className="h-x">
            {convertDurationToHours(
              convertToMinutes(trainComponent.properties.duration)
            )}
          </span>
        </div>
        <div className="dotted-line">
          <div className="vector-18-x"></div>
        </div>
        <div className="datentime">
          <div className="date">
            <span className="wed-15-may-1">
              {formatDate(trainComponent.properties.arrival_time)}
            </span>
          </div>
          <div className="time">
            <span className="container-1">
              {formatTime(trainComponent.properties.arrival_time)}
            </span>
          </div>
        </div>
      </div>
      <div className="train-vector">
        <div className="vector-16-x"></div>
        {/* <img className="vector-16" src="assets/vectors/Vector165_x2.svg" /> */}
        <div className="train-icon">
          <img className="vector-1" src={Train} />
        </div>
        <div className="vector-17-x"></div>
      </div>
      <div className="train-details">
        <div className="train-top">
          <div className="airport-name">
            <p className="vijayawada-junction-railway-station-vijayawada">
              <span className="vijayawada-junction-railway-station-vijayawada-sub-9 capitalize">
                {trainComponent.properties.from_station_name?.toLowerCase()},{" "}
                {trainComponent.properties.origin_city?.toLowerCase()}
              </span>
            </p>
          </div>
          <button
            onClick={() => {
              setQuotaOpen((prev) => !prev);
            }}
            className="quota-select relative"
          >
            <div className="text">
              <span
                className="quotas text-sm"
                style={{ fontFamily: "Poppins" }}
              >
                Quota
              </span>
            </div>
            <div className="bx-chevron-down">
              <img className="vector-2" src={Down_arrow} />
            </div>
            {quotaOpen && (
              <div className="absolute divide-y divide-black border border-black flex flex-col top-[120%] left-0 bg-white rounded-sm text-sm px-2 w-full z-30">
                <button
                  onClick={() => {
                    setSelectedPrice(
                      trainComponent.properties.prices.general[selectedClass]
                    );
                    setSelectedQuota("general");
                  }}
                  style={{
                    fontFamily: "Poppins",
                  }}
                  className="text-left pl-2 py-2 pb-1"
                >
                  General
                </button>
                <button
                  onClick={() => {
                    setSelectedPrice(
                      trainComponent.properties.prices.tatkal[selectedClass]
                    );
                    setSelectedQuota("tatkal");
                  }}
                  style={{
                    fontFamily: "Poppins",
                  }}
                  className="text-left pl-2 py-2 pt-1"
                >
                  Taktal
                </button>
              </div>
            )}
          </button>
        </div>
        <div className="train-mid">
          <div className="train-name-container">
            <div className="train-name-contain">
              <div className="train-name">
                <span className="train-name-1">
                  {trainComponent.properties.train_name} #
                  {trainComponent.properties.train_number}
                </span>
              </div>
              <div
                className="container"
                onClick={() => {
                  getFares(
                    trainComponent.properties.train_number,
                    trainComponent.properties.station_from,
                    trainComponent.properties.station_to
                  );
                }}
              >
                <img className="reload" src={Reload} />
              </div>
            </div>
          </div>
          <div className="coach-main">
            <button
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 25, 100, -10);
              }}
              disabled={arrowDisable}
              className="arrow-drop-left"
            >
              <img className="vector-10-x" src={Arrow_left} />
            </button>
            <div className="coach" ref={elementRef}>
              {trainComponent.properties.class_type && (
                <div className="classes">
                  <div className="container-x">
                    {trainComponent.properties.class_type.map(
                      (classtype, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            if (
                              !(
                                trainComponent.properties.prices &&
                                trainComponent.properties.prices[selectedQuota]
                              )
                            ) {
                              getFares(
                                trainComponent.properties.train_number,
                                trainComponent.properties.station_from,
                                trainComponent.properties.station_to
                              );
                            } else {
                              setselectedClass(classtype);
                              setSelectedPrice(
                                trainComponent.properties.prices[selectedQuota][
                                  classtype
                                ]
                              );
                            }
                          }}
                          className={`${
                            trainComponent.properties.prices &&
                            trainComponent.properties.prices[selectedQuota]
                              ? selectedClass == classtype
                                ? "train-class-active"
                                : "train-class"
                              : "train-class-disabled"
                          }`}
                        >
                          <div className="class-top">
                            <div className="class-type">
                              <span className="a">{classtype}</span>
                            </div>
                            <div className="class-price">
                              <p className="container-2">
                                <span className="container-2-sub-0"></span>
                                <span>
                                  ₹
                                  {(trainComponent.properties.prices &&
                                    trainComponent.properties.prices[
                                      selectedQuota
                                    ] &&
                                    trainComponent.properties.prices[
                                      selectedQuota
                                    ][classtype]) ??
                                    "--"}
                                </span>
                              </p>
                            </div>
                          </div>
                          <span className="available-3">
                            {trainComponent.properties.prices &&
                            trainComponent.properties.prices[selectedQuota]
                              ? "Available: --"
                              : "Refresh"}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 25, 100, 10);
              }}
              className="arrow-drop-right"
            >
              <img className="vector-101" src={Arrow_right} />
            </button>
          </div>
        </div>
        <div className="station-name">
          <p className="station-name-text">
            <span className="station-name-text-sub-11 capitalize">
              {trainComponent.properties.to_station_name?.toLowerCase()},{" "}
              {trainComponent.properties.destination_city?.toLowerCase()}{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TrainIndividual;
