import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import LandingPage34 from "../pages/LandingPage";
import ModesPage from "../pages/ModesPage";
import TfcardsPage from "../pages/CardsPage";
import "../pages/LandingPage.css";
// import Cardpage from "../pages/Cardpage";
// import Landing from "../pages/Landing";
// import Modepage from "../pages/Modepage";

export default function Router() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage34 />} />
          <Route path="/details" element={<TfcardsPage />} />
          <Route path="/modes" element={<ModesPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
