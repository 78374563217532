// import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useMemo, useRef } from "react";
import "./CardsPage.css";
import TrainFlightCard from "../components/TrainFlightCard.jsx";
import ReactSlider from "react-slider";
import TrainTrainCard from "../components/TrainTrainCard.jsx";
import FlightCard from "../components/FlightCard.jsx";
import FlightTrainCard from "../components/FlightTrainCard.jsx";
import TrainCard from "../components/TrainCard.jsx";
import TrainFlightTrainCard from "../components/TrainFlightTrainCard.jsx";
import { useNavigate } from "react-router-dom"; // Import the hook
import Trainy from "../assets/vectors1/Trainy.svg";
import Plane from "../assets/vectors1/Plane_R.svg";
import Sunrise from "../assets/vectors1/Sunrise.svg";
import Midsun from "../assets/vectors1/Midsun.svg";
import Sunset from "../assets/vectors1/Sunset.svg";
import Moonrise from "../assets/vectors1/Moonrise.svg";

import Arrowleft from "../assets/vectors1/Arrow_left.svg";
import Arrowright from "../assets/vectors1/Arrow_right.svg";
import infoicon from "../assets/vectors/BxInfoCircle1_x2.svg";
import Switch from "react-switch";
import "@dotlottie/player-component";
import Header from "../components/Header";
import Headertft from "../components/Headertft";
import { useSelector, useDispatch } from "react-redux";
import {
  setFromCity,
  setFromState,
  setToCity,
  setToState,
  setAdults,
  setDepartureDate,
  setData,
} from "../store/dataSlice";

import { Sort } from "../utils/sort";
import { convertToMinutes } from "../utils/misc";

export default function CardsPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { mode, intermediate } = location.state || {};

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handletoggle = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleStyle = {
    backgroundColor: checked ? "#04042b" : "#B8B8B8", // Green when checked, red when unchecked
  };

  const fromCity = useSelector((state) => state.data.fromCity);
  const toCity = useSelector((state) => state.data.toCity);
  const fromState = useSelector((state) => state.data.fromState);
  const toState = useSelector((state) => state.data.toState);
  const departureDate = useSelector((state) => state.data.departureDate);
  const [selectedDate, setSelectedDate] = useState(departureDate);
  const adults = useSelector((state) => state.data.adults);
  const dateWiseData = useSelector((state) => state.data.dateWiseData);

  const [modeData, setModeData] = useState([]);

  useEffect(() => {
    console.log("calling effect");

    const flightData = dateWiseData[selectedDate]?.flightData ?? [];
    const trainData = dateWiseData[selectedDate]?.trainData ?? [];
    const trainTrainData = dateWiseData[selectedDate]?.trainTrainData ?? [];

    const trainFlightIntermediateObject =
      dateWiseData[selectedDate]?.trainFlightIntermediateObject ?? {};
    const flightTrainIntermediateObject =
      dateWiseData[selectedDate]?.flightTrainIntermediateObject ?? {};
    const trainFlightTrainIntermediateObject =
      dateWiseData[selectedDate]?.trainFlightTrainIntermediateObject ?? {};
    const trainTrainIntermediateObject =
      dateWiseData[selectedDate]?.trainTrainIntermediateObject ?? {};

    switch (mode) {
      case "flight":
        setModeData(flightData);
        break;
      case "train":
        setModeData(trainData);
        console.log(trainData);
        break;
      case "trainFlight":
        setModeData(trainFlightIntermediateObject[intermediate]);
        break;
      case "flightTrain":
        setModeData(flightTrainIntermediateObject[intermediate]);
        break;
      case "trainTrain":
        setModeData(trainTrainIntermediateObject[intermediate]);
        break;
      case "trainFlightTrain":
        setModeData(trainFlightTrainIntermediateObject[intermediate]);
        break;
    }
  }, [mode, dateWiseData]);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 250);
  // }, [modeData]);

  const [value, setValue] = useState(0);
  const [cheapest, setCheapest] = useState(false);
  const [fastest, setFastest] = useState(true);
  const [best, setBest] = useState(false);

  const [isClicked, setIsClicked] = useState("Fastest");

  const [dbefore6am, setdbefore6am] = useState(false);
  const [d6amto12pm, setd6amto12pm] = useState(false);
  const [d12pmto6pm, setd12pmto6pm] = useState(false);
  const [d6pmto12am, setd6pmto12am] = useState(false);

  const [abefore6am, setabefore6am] = useState(false);
  const [a6amto12pm, seta6amto12pm] = useState(false);
  const [a12pmto6pm, seta12pmto6pm] = useState(false);
  const [a6pmto12am, seta6pmto12am] = useState(false);

  const [direct, setDirect] = useState(false);
  const [oneStop, setOneStop] = useState(false);
  const [twoPlusStops, setTwoPlusStops] = useState(false);
  const [Sector, setSector] = useState(true);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //airline filter function
  const [airlineFilters, setAirlineFilters] = useState({
    air_india: false,
    air_india_express: false,
    akasa_air: false,
    indigo: false,
    vistara: false,
    spicejet: false,
    starair: false,
  });

  const cheapestPrice =
    modeData && modeData.length > 0
      ? Math.min(...modeData.map((flight) => flight.total_price))
      : null;

  const validCheapestPrice = cheapestPrice === Infinity ? null : cheapestPrice;

  const fastestPrice =
    modeData && modeData.length > 0
      ? modeData.reduce((fastest, flight) => {
          return flight.total_duration < fastest.total_duration
            ? flight
            : fastest;
        }).total_price
      : null;

  const maxPriceRange =
    modeData && modeData.length > 0
      ? modeData.reduce(
          (max, flight) => Math.max(max, flight.properties?.price),
          0
        )
      : null;
  const [range, setRange] = useState([0, maxPriceRange]);
  useEffect(() => {
    setRange([0, maxPriceRange]);
  }, [maxPriceRange]);
  // console.log(maxPriceRange);

  const validFastestPrice = fastestPrice === Infinity ? null : fastestPrice;

  const directPrice =
    mode === "flight" && modeData && modeData.length > 0
      ? Math.min(
          ...modeData
            .filter((flight) => flight.properties.stop_count === 0)
            .map((flight) => flight.total_price)
        )
      : null;

  const validDirectPrice = directPrice === Infinity ? null : directPrice;

  const oneStopPrice =
    mode === "flight" && modeData && modeData.length > 0
      ? Math.min(
          ...modeData
            .filter((flight) => flight.properties.stop_count === 1)
            .map((flight) => flight.total_price)
        )
      : null;

  const validOneStopsPrice = oneStopPrice === Infinity ? null : oneStopPrice;

  const twoPlusStopsPrice =
    mode === "flight" && modeData && modeData.length > 0
      ? modeData
          .filter((flight) => flight.properties.stop_count >= 2)
          .map((flight) => flight.total_price)
          .reduce((minPrice, price) => Math.min(minPrice, price), Infinity)
      : null;

  const validTwoPlusStopsPrice =
    twoPlusStopsPrice === Infinity ? null : twoPlusStopsPrice;

  const handleAirlineCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setAirlineFilters({
      ...airlineFilters,
      [name]: checked,
    });
    handleFilter();
  };

  async function getFares(train_number, fromStation, toStation) {
    console.log(train_number);

    const url = `https://irctc1.p.rapidapi.com/api/v2/getFare?trainNo=${train_number}&fromStationCode=${fromStation}&toStationCode=${toStation}`;
    const options = {
      method: "GET",
      headers: {
        "x-rapidapi-key": process.env.REACT_APP_API_KEY,
        "x-rapidapi-host": "irctc1.p.rapidapi.com",
      },
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();

      console.log(result);
      let prices = {};
      let generalprices = {};
      let tatkalprices = {};

      result.data?.general.forEach((fare, i) => {
        generalprices[fare.classType] = fare.fare;
      });
      result.data?.tatkal.forEach((fare, i) => {
        tatkalprices[fare.classType] = fare.fare;
      });

      prices["general"] = generalprices;
      prices["tatkal"] = tatkalprices;

      console.log(prices);

      const newData = modeData.map((combination, i) => {
        if (
          mode == "train" &&
          combination.properties.train_number == train_number
        ) {
          const temp = {
            ...combination,
            properties: {
              ...combination.properties,
              prices: prices,
            },
          };

          return temp;
        }
        if (
          (mode == "trainFlight" || mode == "flightTrain") &&
          combination.train.properties.train_number == train_number
        ) {
          let temp = { ...combination };
          temp.train = {
            ...combination.train,
            properties: {
              ...combination.train.properties,
              prices: prices,
            },
          };

          return temp;
        }

        if (mode == "trainTrain" || mode == "trainFlightTrain") {
          if (combination.train1.properties.train_number == train_number) {
            let temp = { ...combination };
            temp.train1 = {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                prices: prices,
              },
            };

            return temp;
          } else if (
            combination.train2.properties.train_number == train_number
          ) {
            let temp = { ...combination };
            temp.train2 = {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                prices: prices,
              },
            };

            return temp;
          }
        }

        return combination;
      });

      dispatch(setData({ type: mode, data: newData }));
    } catch (error) {
      console.error(error);
    }
  }

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleFilter = () => {
    setLoading(true);
    setSector(false);
    setTimeout(() => {
      setLoading(false);
      setSector(true);
    }, 500);
  };

  function getModeData(date) {
    if (mode == "flight") {
      return dateWiseData[date].flightData;
    } else if (mode == "train") {
      return dateWiseData[date].trainData;
    } else if (mode == "trainTrain") {
      return dateWiseData[date].trainTrainIntermediateObject[intermediate];
    } else if (mode == "trainFlight") {
      return dateWiseData[date].trainFlightIntermediateObject[intermediate];
    } else if (mode == "flightTrain") {
      return dateWiseData[date].flightTrainIntermediateObject[intermediate];
    }
  }

  const sortedData = useMemo(() => {
    return Sort({
      mode,
      modeData,
      range,
      dbefore6am,
      d6amto12pm,
      d12pmto6pm,
      d6pmto12am,
      abefore6am,
      a6amto12pm,
      a12pmto6pm,
      a6pmto12am,
      airlineFilters,
      direct,
      oneStop,
      twoPlusStops,
      cheapest,
      fastest,
    });
  }, [
    mode,
    modeData,
    range,
    dbefore6am,
    d6amto12pm,
    d12pmto6pm,
    d6pmto12am,
    abefore6am,
    a6amto12pm,
    a12pmto6pm,
    a6pmto12am,
    airlineFilters,
    direct,
    oneStop,
    twoPlusStops,
    cheapest,
    fastest,
  ]);

  async function CallDate(newDepartureDate) {
    setModeData([]);
    setLoading(true);
    setSelectedDate(newDepartureDate);
    if (dateWiseData[newDepartureDate] && getModeData(newDepartureDate)) {
      console.log("already present");
      setModeData(getModeData(newDepartureDate));
      setLoading(false);
    } else {
      console.log("calling for", newDepartureDate, intermediate, mode);

      const response = await fetch("https://wayport.in/api/individual", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode,
          source_city: fromCity,
          desti_city: toCity,
          intermediate_city: intermediate,
          source_state: fromState,
          desti_state: toState,
          departure_date: newDepartureDate,
          adults,
        }),
      });

      console.log(response);

      const result = await response.json();
      console.log("Result", result);

      const modifiedData = await ModifyData(mode, result);

      dispatch(
        setData({
          type: mode,
          data: modifiedData,
          date: newDepartureDate,
        })
      );
      setModeData(modifiedData);
      setLoading(false);
    }
  }

  async function ModifyData(mode, data) {
    if (mode == "flight") {
      return await withFlightNames(data);
    } else if (mode == "train") {
      return await withStationNames(data);
    } else if (mode == "trainFlight") {
      return await modifyTFData(data, mode);
    } else if (mode == "flightTrain") {
      return await modifyTFData(data, mode);
    } else if (mode == "trainTrain") {
      return await modifyTTData(data);
    } else if (mode == "trainFlightTrain") {
      return await modifyTFTData(data);
    }
  }

  async function withFlightNames(flightData) {
    console.log(flightData);
    if (!flightData || flightData.length === 0 || "train" in flightData[0]) {
      console.log("No data available for flight.");
      return []; // Early exit if flightData is undefined or null
    }

    const newData = await Promise.all(
      flightData.map(async (combination, i) => {
        if ("segments" in combination.properties) {
          const newsegments = await Promise.all(
            combination.properties.segments.map(async (segment, si) => {
              const airportNames = await getAirportNames(
                segment.origin.display_code,
                segment.destination.display_code
              );

              return {
                ...segment,
                origin: {
                  ...segment.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...segment.destination,
                  name: airportNames.destination_airport_name,
                },
              };
            })
          );
          const flightPriceInNumber = combination.properties.price;

          return {
            ...combination,
            properties: {
              ...combination.properties,
              segments: newsegments,
            },
            total_duration: combination.properties.total_duration,
            total_price: flightPriceInNumber,
          };
        } else {
          const airportNames = await getAirportNames(
            combination.properties.origin.display_code,
            combination.properties.destination.display_code
          );

          const flightPriceInNumber = combination.properties.price;

          return {
            ...combination,
            properties: {
              ...combination.properties,
              origin: {
                ...combination.properties.origin,
                name: airportNames.origin_airport_name,
              },
              destination: {
                ...combination.properties.destination,
                name: airportNames.destination_airport_name,
              },
            },
            total_duration: combination.properties.duration,
            total_price: flightPriceInNumber,
          };
        }
      })
    );
    console.log(newData);

    return newData;
  }

  async function withStationNames(trainData) {
    console.log(trainData);
    if (!trainData || trainData.length === 0 || "flight" in trainData[0]) {
      console.log("No data available for flight.");
      return []; // Early exit if trainData is undefined or null
    }

    const newData = await Promise.all(
      trainData.map(async (combination, i) => {
        console.log(combination);

        const stationNames = await getStationNames(
          combination.properties.station_from,
          combination.properties.station_to
        );

        // const prices = await getFares(
        //   combination.properties.train_number,
        //   combination.properties.station_from,
        //   combination.properties.station_to
        // );
        // const trainFareInNumber = getAvailableClass(prices.general) || 0;
        const trainFareInNumber = 0;

        console.log(trainFareInNumber);

        return {
          ...combination,
          properties: {
            ...combination.properties,
            from_station_name: stationNames.origin_station_name,
            to_station_name: stationNames.destination_station_name,
            // prices: prices,
          },
          total_duration: convertToMinutes(combination.properties.duration),
          total_price: trainFareInNumber,
        };
      })
    );
    console.log(newData);

    return newData;
  }

  async function modifyTFTData(modeData) {
    console.log(modeData);
    if (!modeData) {
      console.log("No data available. for mode TFT");
      return; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const train1DurationInMinutes = convertToMinutes(
            combination.train1.properties.duration
          );

          const flightDurationInMinutes =
            combination.flight.properties.duration;

          const train2DurationInMinutes = convertToMinutes(
            combination.train2.properties.duration
          );
          const buffer1 =
            (new Date(combination.flight.properties.departure_time) -
              new Date(combination.train1.properties.arrival_time)) /
            (1000 * 60);

          const buffer2 =
            (new Date(combination.train2.properties.departure_time) -
              new Date(combination.flight.properties.arrival_time)) /
            (1000 * 60);

          const totalDurationInMinutes =
            train1DurationInMinutes +
            train2DurationInMinutes +
            flightDurationInMinutes +
            buffer1 +
            buffer2;

          console.log(totalDurationInMinutes);

          const flightPriceInNumber = combination.flight.properties.price;

          // const prices1 = await getFares(
          //   combination.train1.properties.train_number,
          //   combination.train1.properties.station_from,
          //   combination.train1.properties.station_to
          // );
          // const prices2 = await getFares(
          //   combination.train2.properties.train_number,
          //   combination.train2.properties.station_from,
          //   combination.train2.properties.station_to
          // );

          // console.log(prices1, prices2);

          // const train1FareInNumber = getAvailableClass(prices1.general) || 0;
          // const train2FareInNumber = getAvailableClass(prices2.general) || 0;
          const train1FareInNumber = 0;
          const train2FareInNumber = 0;

          const totalPrice =
            train1FareInNumber + train2FareInNumber + flightPriceInNumber;

          console.log(totalPrice);

          const station1Names = await getStationNames(
            combination.train1.properties.station_from,
            combination.train1.properties.station_to
          );

          const station2Names = await getStationNames(
            combination.train2.properties.station_from,
            combination.train2.properties.station_to
          );

          const airportNames = await getAirportNames(
            combination.flight.properties.origin.display_code,
            combination.flight.properties.destination.display_code
          );

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            train1: {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                // prices: prices1,
                from_station_name: station1Names.origin_station_name,
                to_station_name: station1Names.destination_station_name,
              },
            },
            train2: {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                // prices: prices2,
                from_station_name: station2Names.origin_station_name,
                to_station_name: station2Names.destination_station_name,
              },
            },
            flight: {
              ...combination.flight,
              properties: {
                ...combination.flight.properties,
                origin: {
                  ...combination.flight.properties.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...combination.flight.properties.destination,
                  name: airportNames.destination_airport_name,
                },
              },
            },
            total_price: totalPrice,
          };
        })
      );

      return newData;
    } catch (error) {
      console.error("Error processing data:", error.message);
      return modeData;
    }
  }

  async function modifyTFData(modeData, mode) {
    console.log(mode, modeData);
    if (!modeData) {
      console.log("No data available. for mode", mode);
      return; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const trainDurationInMinutes = convertToMinutes(
            combination.train.properties.duration
          );

          const flightDurationInMinutes =
            combination.flight.properties.duration;

          const buffer =
            mode == "flightTrain"
              ? Math.abs(
                  (new Date(combination.train.properties.departure_time) -
                    new Date(combination.flight.properties.arrival_time)) /
                    (1000 * 60)
                )
              : Math.abs(
                  (new Date(combination.flight.properties.departure_time) -
                    new Date(combination.train.properties.arrival_time)) /
                    (1000 * 60)
                );

          const totalDurationInMinutes =
            trainDurationInMinutes + flightDurationInMinutes + buffer;

          // const flightPriceInNumber = parseInt(
          //   combination.flight.properties.price.replace(/[^\d]/g, ""),
          //   10
          // );

          const flightPriceInNumber = combination.flight.properties.price;

          // const prices = await getFares(
          //   combination.train.properties.train_number,
          //   combination.train.properties.station_from,
          //   combination.train.properties.station_to
          // );
          // const trainFareInNumber = getAvailableClass(prices.general) || 0;
          const trainFareInNumber = 0;

          const totalPrice = trainFareInNumber + flightPriceInNumber;

          const stationNames = await getStationNames(
            combination.train.properties.station_from,
            combination.train.properties.station_to
          );

          const airportNames = await getAirportNames(
            combination.flight.properties.origin.display_code,
            combination.flight.properties.destination.display_code
          );

          console.log("flights", airportNames);

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            train: {
              ...combination.train,
              properties: {
                ...combination.train.properties,
                from_station_name: stationNames.origin_station_name,
                to_station_name: stationNames.destination_station_name,
                // prices: prices,
              },
            },
            flight: {
              ...combination.flight,
              properties: {
                ...combination.flight.properties,
                origin: {
                  ...combination.flight.properties.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...combination.flight.properties.destination,
                  name: airportNames.destination_airport_name,
                },
              },
            },
            total_price: totalPrice,
          };
        })
      );

      return newData;
    } catch (error) {
      console.error("Error processing data:", error);
      return modeData;
    }
  }

  async function modifyTTData(modeData) {
    console.log(modeData);
    if (!modeData) {
      console.log("No data available. for ttmode");
      return; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const train1DurationInMinutes = convertToMinutes(
            combination.train1.properties.duration
          );

          const train2DurationInMinutes = convertToMinutes(
            combination.train2.properties.duration
          );

          const buffer = Math.abs(
            (new Date(combination.train1.properties.departure_time) -
              new Date(combination.train2.properties.arrival_time)) /
              (1000 * 60)
          );

          const totalDurationInMinutes =
            train1DurationInMinutes + train2DurationInMinutes + buffer;

          const train1FareInNumber = 0;
          const train2FareInNumber = 0;

          const totalPrice = train1FareInNumber + train2FareInNumber;
          const stationNames1 = await getStationNames(
            combination.train1.properties.station_from,
            combination.train1.properties.station_to
          );
          const stationNames2 = await getStationNames(
            combination.train2.properties.station_from,
            combination.train2.properties.station_to
          );

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            total_price: totalPrice,
            train1: {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                from_station_name: stationNames1.origin_station_name,
                to_station_name: stationNames1.destination_station_name,
              },
            },
            train2: {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                from_station_name: stationNames2.origin_station_name,
                to_station_name: stationNames2.destination_station_name,
              },
            },
          };
        })
      );

      console.log(newData);

      return newData;
    } catch (error) {
      console.error("Error processing data:", error);
      return modeData;
    }
  }

  let airportNamesByCode = {};

  async function getAirportNames(fromCode, toCode) {
    try {
      // 65.1.76.109
      if (airportNamesByCode[fromCode] && airportNamesByCode[toCode]) {
        console.log("found already existed for", fromCode, toCode);
        return {
          origin_airport_name: airportNamesByCode[fromCode],
          destination_airport_name: airportNamesByCode[toCode],
        };
      } else {
        const response = await fetch("https://wayport.in/api/airport", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            origin_airport_code: fromCode,
            destination_airport_code: toCode,
          }),
        });
        const result = await response.json();

        airportNamesByCode[fromCode] = result.origin_airport_name;
        airportNamesByCode[toCode] = result.destination_airport_name;

        return result;
      }
    } catch (error) {
      console.error(error);
      return {
        origin: { airport_name: "Failed to fetch" },
        destination: { airport_name: "Failed to fetch" },
      };
    }
  }

  let stationNamesByCode = {};

  //TODO: Optimise later by taking only one station at a time.
  async function getStationNames(fromCode, toCode) {
    try {
      if (stationNamesByCode[fromCode] && stationNamesByCode[toCode]) {
        console.log("found already existed for", fromCode, toCode);
        return {
          origin_station_name: stationNamesByCode[fromCode],
          destination_station_name: stationNamesByCode[toCode],
        };
      } else {
        const response = await fetch("https://wayport.in/api/station", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            origin_station_code: fromCode,
            destination_station_code: toCode,
          }),
        });
        const result = await response.json();
        console.log(result);

        stationNamesByCode[fromCode] = result.origin_station_name;
        stationNamesByCode[toCode] = result.destination_station_name;

        return result;
      }
    } catch (error) {
      console.error(error);
      return {
        origin_station_name: "Failed to fetch",
        destination_station_name: "Failed to fetch",
      };
    }
  }

  return (
    <div className="tfcards-page">
      <div className="cardpage-main">
        <div className="bg-white sticky top-[100px] w-full z-20 flex flex-col rounded-[8px]">
          {mode === "flight" ? (
            <Header
              mode="flight"
              fromCity={
                modeData[0]?.properties.stop_count >= 1
                  ? modeData[0]?.properties?.segments[0]?.origin_city
                  : modeData[0]?.properties.stop_count >= 0
                  ? modeData[0]?.properties?.origin_city
                  : fromCity
              }
              toCity={
                modeData[0]?.properties.stop_count >= 1
                  ? modeData[0]?.properties?.segments[1]?.destination_city
                  : modeData[0]?.properties.stop_count >= 0
                  ? modeData[0]?.properties?.destination_city
                  : toCity
              }
              departureDate={selectedDate}
              CallDate={CallDate}
            />
          ) : mode === "train" ? (
            <Header
              mode="train"
              fromCity={
                modeData[0]?.properties?.origin_city
                  ? modeData[0]?.properties.origin_city
                  : fromCity
              }
              toCity={
                modeData[0]?.properties?.destination_city
                  ? modeData[0]?.properties.destination_city
                  : toCity
              }
              departureDate={selectedDate}
              CallDate={CallDate}
            />
          ) : mode === "trainTrain" ? (
            <Header
              mode="trainTrain"
              fromCity={
                modeData[0]?.train1?.properties?.origin_city
                  ? modeData[0].train1.properties.origin_city
                  : fromCity
              }
              toCity={
                modeData[0]?.train2?.properties?.destination_city
                  ? modeData[0].train2.properties.destination_city
                  : toCity
              }
              intermediate={modeData && modeData[0]?.intermediate}
              departureDate={selectedDate}
              CallDate={CallDate}
            />
          ) : mode === "trainFlight" ? (
            <Header
              mode="trainFlight"
              fromCity={
                modeData[0]?.train?.properties?.origin_city
                  ? modeData[0].train.properties.origin_city
                  : fromCity
              }
              toCity={
                modeData[0]?.flight?.properties?.destination?.city
                  ? modeData[0].flight.properties.destination_city
                  : toCity
              }
              intermediate={modeData && modeData[0]?.intermediate}
              departureDate={selectedDate}
              CallDate={CallDate}
            />
          ) : mode === "flightTrain" ? (
            <Header
              mode="flightTrain"
              fromCity={
                modeData[0]?.flight?.properties?.origin?.city
                  ? modeData[0].flight.properties.origin_city
                  : fromCity
              }
              toCity={
                modeData[0]?.train?.properties?.destination_city
                  ? modeData[0].train.properties.destination_city
                  : toCity
              }
              intermediate={modeData && modeData[0]?.intermediate}
              departureDate={selectedDate}
              CallDate={CallDate}
            />
          ) : mode === "trainFlightTrain" ? (
            <Headertft
              mode="trainFlightTrain"
              fromCity={
                modeData[0]?.train1?.properties?.origin_city
                  ? modeData[0].train1.properties.origin_city
                  : fromCity
              }
              toCity={
                modeData[0]?.train2?.properties?.destination_city
                  ? modeData[0].train2.properties.destination_city
                  : toCity
              }
              intermediate={modeData && modeData[0]?.intermediate1}
              intermediate2={modeData && modeData[0]?.intermediate2}
              departureDate={selectedDate}
              CallDate={CallDate}
            />
          ) : (
            <div> No data</div>
          )}
        </div>
        <div className="cardspage-bottom">
          <div
            className={
              "w-full h-[205px] fixed left-0 top-0 z-[1] bg-gradient-to-b from-[#f2f3f4] from-100% to-transparent"
            }
          ></div>
          <div className={"sidebar"}>
            <div className="filter-options">
              <div
                onClick={() => {
                  setIsClicked("Cheapest");
                  setCheapest(true);
                  setFastest(false);
                  setBest(false);
                  handleFilter();
                }}
                className={isClicked === "Cheapest" ? "activator" : "modes"}
              >
                <div className="cheapest">
                  <span className="cheapest-1">Cheapest</span>
                </div>
                <div className="cheapest-price">
                  <span className="container-xyz-0">
                    {validCheapestPrice !== null
                      ? `₹${validCheapestPrice}`
                      : "N/A"}
                  </span>
                </div>
              </div>
              <div
                onClick={() => {
                  setIsClicked("Fastest");
                  setCheapest(false);
                  setFastest(true);
                  setBest(false);
                  handleFilter();
                }}
                className={isClicked === "Fastest" ? "activator" : "modes-1"}
              >
                <div className="fastest">
                  <span className="fastest-1">Fastest</span>
                </div>
                <div className="fastest-price">
                  <p className="container-xyz-1">
                    <span className="container-xyz-1-sub-0">
                      {validFastestPrice !== null
                        ? `₹${validFastestPrice}`
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setIsClicked("Best");
                  setCheapest(false);
                  setFastest(false);
                  setBest(true);
                  handleFilter();
                }}
                className={isClicked === "Best" ? "activator" : "modes-2"}
              >
                <div className="fastest">
                  <img
                    className="bx-info-circle"
                    width={16}
                    height={16}
                    src={infoicon}
                  />
                  <span className="fastest-1">Best</span>
                </div>
                <div className="fastest-price">
                  <p className="container-xyz-1">
                    <span className="container-xyz-1-sub-0">---</span>
                  </p>
                </div>
                {/* <div className="best">
                    <img
                      className="bx-info-circle"
                      width={16}
                      height={16}
                      src={infoicon}
                    />
                    <div className="best-1">Best</div>
                  </div>
                  <div className="best-price">
                    <p className="container-xyz">
                      <span className="container-xyz-sub-0">₹8717</span>
                    </p>
                  </div> */}
              </div>
            </div>
            <div className="side-bar">
              <div className="set-price-alerts">
                <div className="alerts-text">
                  <div className="alerts-text-top">
                    <span className="set-up-price-alerts">
                      Set up price alerts
                    </span>
                  </div>
                  <div className="alerts-text-details">
                    <span className="receive-alerts-when-the-price-changes">
                      Receive alerts when the price changes
                    </span>
                  </div>
                </div>
                <div className="toggle-on">
                  <Switch
                    onChange={handletoggle}
                    checked={checked}
                    offColor="#B8B8B8"
                    onColor="#B8B8B8"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40} // Set the width of the switch
                    height={19} // Set the height of the switch
                    handleDiameter={18} // Set the diameter of the switch handle
                    handleStyle={handleStyle}
                  />
                </div>
              </div>
              {mode === "flight" && (
                <div className="stops-section">
                  <div className="stops">
                    <span className="stops-1">Stops</span>
                  </div>
                  <div className="stops-options">
                    <div className="frame-671">
                      <div className="frame-664">
                        <input
                          type="checkbox"
                          className="direct-checkbox"
                          checked={direct}
                          onChange={(e) => {
                            setDirect(e.target.checked);
                            handleFilter();
                          }}
                        />
                      </div>
                      <div className="frame-667">
                        <div className="frame-665">
                          <span className="direct">Direct</span>
                        </div>
                        <div className="frame-666">
                          <p className="from-rs-2800">
                            <span className="from-rs-2800-sub-3"></span>
                            <span>
                              From Rs{" "}
                              {validDirectPrice !== null
                                ? validDirectPrice
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="frame-669">
                      <div className="frame-6641">
                        <input
                          type="checkbox"
                          className="stop1-checkbox"
                          checked={oneStop}
                          onChange={(e) => {
                            setOneStop(e.target.checked);
                            handleFilter();
                          }}
                        />
                      </div>
                      <div className="frame-6671">
                        <div className="frame-6651">
                          <span className="stop">1 Stop</span>
                        </div>
                        <div className="frame-6661">
                          <span className="from-rs-28001">
                            From Rs{" "}
                            {validOneStopsPrice !== null
                              ? validOneStopsPrice
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="frame-670">
                      <div className="frame-6642">
                        <input
                          type="checkbox"
                          className="stop2-checkbox"
                          checked={twoPlusStops}
                          onChange={(e) => {
                            setTwoPlusStops(e.target.checked);
                            handleFilter();
                          }}
                        />
                      </div>
                      <div className="frame-6672">
                        <div className="frame-6652">
                          <span className="stops-2">2+ Stops</span>
                        </div>
                        <div className="frame-6662">
                          <span className="from-rs-4500">
                            From Rs{" "}
                            {validTwoPlusStopsPrice !== null
                              ? validTwoPlusStopsPrice
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {mode === "flightTrain" ||
                            mode === "trainFlight" ||
                            mode === "trainTrain" ||
                            mode === "trainFlightTrain" ? (
                                <div className="railway-station-bar">
                                    <div className="frame-276">
                                        <span className="railway-stations">
                                            Railway Stations
                                        </span>
                                    </div>
                                    <div className="frame-689">
                                        <div className="origin-departure">
                                            <span className="origin-station">
                                                Boarding Station
                                            </span>
                                        </div>
                                        <div className="frame-688">
                                            <div className="boarding-option">
                                                <div className="frame-681">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6811">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-1">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6812">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-2">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6813">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-3">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6814">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-4">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6815">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-5">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frame-690">
                                        <div className="origin-departure-1">
                                            <span className="origin-station-1">
                                                Arrival Station
                                            </span>
                                        </div>
                                        <div className="frame-6881">
                                            <div className="boarding-option">
                                                <div className="frame-6816">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-6">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6817">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-7">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6818">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-8">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-6819">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-9">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-68110">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-10">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="boarding-option">
                                                <div className="frame-68111">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxer"
                                                    />
                                                </div>
                                                <div className="boarding-name">
                                                    <span className="vijayawada-jn-bza-11">
                                                        Vijayawada Jn-Bza
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )} */}

              <div className="price-range">
                <div className="price-range-1">Price Range</div>
                <div className="frame-268" style={{ marginTop: 20 }}>
                  <div className="price-range-slider">
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      defaultValue={[0, maxPriceRange]}
                      min={0}
                      max={maxPriceRange}
                      step={1}
                      ariaLabel={["Lower thumb", "Upper thumb"]}
                      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                      onChange={(value) => {
                        setRange(value);
                        handleFilter();
                      }}
                    />
                    <div className="values">
                      <span>{`₹${range[0]}`}</span>
                      <span>{`₹${range[1]}`}</span>
                    </div>
                  </div>
                  {/* <div className="frame-267">
                                            <div className="frame-265">
                                                <span className="rs-0">
                                                    Rs 0
                                                </span>
                                            </div>
                                            <div className="frame-266">
                                                <span className="rs-18563">
                                                    Rs 18,563
                                                </span>
                                            </div>
                                        </div> */}
                </div>
              </div>
              <div className="frame-508">
                <div className="departurarrival">
                  <div className="departure">Departure</div>
                  <div className="frame-700">
                    <div className="frame-698">
                      <div
                        onClick={() => {
                          setdbefore6am(!dbefore6am);
                          handleFilter();
                        }}
                        className={dbefore6am === true ? "activator-11" : "b-6"}
                      >
                        <div className="frame-13">
                          <img className="vector-7" src={Sunrise} />
                        </div>
                        <div className="frame-4761">
                          <span className="before-6-am">Before 6AM</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setd6amto12pm(!d6amto12pm);
                          handleFilter();
                        }}
                        className={
                          d6amto12pm === true ? "activator-11" : "container-3"
                        }
                      >
                        <div className="frame-6">
                          <img className="vector-6" src={Midsun} />
                        </div>
                        <div className="frame-476">
                          <span className="am-12-pm">6AM-12PM</span>
                        </div>
                      </div>
                    </div>
                    <div className="frame-699">
                      <div className="container-4">
                        <div
                          onClick={() => {
                            setd12pmto6pm(!d12pmto6pm);
                            handleFilter();
                          }}
                          className={
                            d12pmto6pm === true ? "activator-11" : "frame-480"
                          }
                        >
                          <div className="frame-460">
                            <img className="vector-8" src={Sunset} />
                          </div>
                          <div className="frame-4762">
                            <span className="pm-6-pm">12PM-6PM</span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setd6pmto12am(!d6pmto12am);
                          handleFilter();
                        }}
                        className={d6pmto12am === true ? "activator-11" : "pm"}
                      >
                        <div className="frame-7">
                          <img className="vector-9" src={Moonrise} />
                        </div>
                        <div className="frame-4763">
                          <span className="pm-12-am">6PM-12AM</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="departurarrival-1">
                  <div className="departure-1">Arrival</div>
                  <div className="frame-7001">
                    <div className="frame-6981">
                      <div
                        onClick={() => {
                          setabefore6am(!abefore6am);
                          handleFilter();
                        }}
                        className={
                          abefore6am === true ? "activator-11" : "b-61"
                        }
                      >
                        <div className="frame-131">
                          <img className="vector-11" src={Sunrise} />
                        </div>
                        <div className="frame-4765">
                          <span className="before-6-am-1">Before 6AM</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          seta6amto12pm(!a6amto12pm);
                          handleFilter();
                        }}
                        className={
                          a6amto12pm === true ? "activator-11" : "container-5"
                        }
                      >
                        <div className="frame-61">
                          <img className="vector-10" src={Midsun} />
                        </div>
                        <div className="frame-4764">
                          <span className="am-12-pm-1">6AM-12PM</span>
                        </div>
                      </div>
                    </div>
                    <div className="frame-6991">
                      <div className="container-6">
                        <div
                          onClick={() => {
                            seta12pmto6pm(!a12pmto6pm);
                            handleFilter();
                          }}
                          className={
                            a12pmto6pm === true ? "activator-11" : "frame-4801"
                          }
                        >
                          <div className="frame-4601">
                            <img className="vector-12" src={Sunset} />
                          </div>
                          <div className="frame-4766">
                            <span className="pm-6-pm-1">12PM-6PM</span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          seta6pmto12am(!a6pmto12am);
                          handleFilter();
                        }}
                        className={
                          a6pmto12am === true ? "activator-11" : "pm-1"
                        }
                      >
                        <div className="frame-71">
                          <img className="vector-13-x" src={Moonrise} />
                        </div>
                        <div className="frame-4767">
                          <span className="pm-12-am-1">6PM-12AM</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {mode !== "train" || mode !== "trainTrain" ? (
                <div className="frame-697">
                  <div className="frame-359">
                    <span className="airlines">Airlines</span>
                  </div>
                  <div className="frame-696">
                    <div className="frame-691">
                      <div className="frame-68112">
                        <input
                          type="checkbox"
                          name="air_india"
                          className="checkboxer"
                          checked={airlineFilters.air_india}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="air-india">Air India</div>
                    </div>
                    <div className="frame-692">
                      <div className="frame-68113">
                        <input
                          type="checkbox"
                          name="air_india_express"
                          className="checkboxer"
                          checked={airlineFilters.air_india_express}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="air-india-express">Air India Express</div>
                    </div>
                    <div className="frame-693">
                      <div className="frame-68114">
                        <input
                          type="checkbox"
                          name="akasa_air"
                          className="checkboxer"
                          checked={airlineFilters.akasa_air}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="akasa-air">Akasa Air</div>
                    </div>
                    <div className="frame-694">
                      <div className="frame-68115">
                        <input
                          type="checkbox"
                          name="indigo"
                          className="checkboxer"
                          checked={airlineFilters.indigo}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="indigo">Indigo</div>
                    </div>
                    <div className="frame-693">
                      <div className="frame-68114">
                        <input
                          type="checkbox"
                          name="starair"
                          className="checkboxer"
                          checked={airlineFilters.starair}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="akasa-air">Star Air</div>
                    </div>
                    <div className="frame-695" style={{ marginBottom: 10 }}>
                      <div className="frame-68116">
                        <input
                          type="checkbox"
                          name="spicejet"
                          className="checkboxer"
                          checked={airlineFilters.spicejet}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="vistara">SpiceJet</div>
                    </div>
                    <div className="frame-695">
                      <div className="frame-68116">
                        <input
                          type="checkbox"
                          name="vistara"
                          className="checkboxer"
                          checked={airlineFilters.vistara}
                          onChange={handleAirlineCheckboxChange}
                        />
                      </div>
                      <div className="vistara">Vistara</div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={`cards-list mt-8 w-[710px]`}>
            {loading ? (
              <div className="w-full flex justify-center">
                <dotlottie-player
                  background="#f2f3f4"
                  direction="1"
                  id="animation_map-with-pulsing-city-dots_e16d5fea-1165-11ee-8615-6bd07bc9a3fb"
                  speed="1"
                  mode="normal"
                  src="https://lottie.host/8d72faf0-32be-42dc-95ab-fcbdd1dcb7d7/fWVcvEyDal.json"
                  autoplay="true"
                  loop="true"
                  style={{
                    width: 200,
                    height: "fit-content",
                  }}
                ></dotlottie-player>
              </div>
            ) : (
              <div className="w-[710px] relative ">
                <p
                  className="text-[#333C45] text-xl font-semibold"
                  style={{
                    marginBottom: 16,
                    marginTop: 2,
                    width: "100%",
                    fontFamily: "Poppins",
                    position: "absolute",
                  }}
                >
                  {sortedData?.length}{" "}
                  {sortedData?.length == 1 ? "Result" : "Results"}
                </p>
              </div>
            )}
            {Sector ? (
              <div className="mt-[40px] flex flex-col gap-6">
                {mode === "flight" ? ( //!! remaining for t and f
                  modeData && modeData.length > 0 ? (
                    sortedData.map((flight, index) => (
                      <FlightCard key={index} flight={flight} />
                    ))
                  ) : (
                    !loading && (
                      <div style={{ width: 600 }}>
                        No flight details available
                      </div>
                    )
                  )
                ) : mode === "train" ? (
                  modeData && modeData.length > 0 ? (
                    sortedData.map((train, index) => (
                      <TrainCard
                        key={index}
                        train={train}
                        fromCity={fromCity}
                        toCity={toCity}
                        getFares={getFares}
                      />
                    ))
                  ) : (
                    !loading && <div>No train details available</div>
                  )
                ) : mode === "trainTrain" ? (
                  modeData && modeData.length > 0 ? (
                    sortedData.map((trainTrain, index) => (
                      <TrainTrainCard
                        key={index}
                        trainTrain={trainTrain}
                        fromCity={fromCity}
                        toCity={toCity}
                        getFares={getFares}
                      />
                    ))
                  ) : (
                    !loading && <div>No trainTrain details available</div>
                  )
                ) : mode === "trainFlight" ? (
                  modeData && modeData.length > 0 ? (
                    sortedData.map((trainFlight, index) => (
                      <TrainFlightCard
                        key={index}
                        trainFlight={trainFlight}
                        fromCity={fromCity}
                        toCity={toCity}
                        getFares={getFares}
                      />
                    ))
                  ) : (
                    !loading && <div>No trainFlight details available</div>
                  )
                ) : mode === "flightTrain" ? (
                  modeData && modeData.length > 0 ? (
                    sortedData.map((flightTrain, index) => (
                      <FlightTrainCard
                        key={index}
                        flightTrain={flightTrain}
                        fromCity={fromCity}
                        toCity={toCity}
                        getFares={getFares}
                      />
                    ))
                  ) : (
                    !loading && <div>No flightTrain details available</div>
                  )
                ) : mode === "trainFlightTrain" ? (
                  modeData && modeData.length > 0 ? (
                    sortedData.map((trainFlightTrain, index) => (
                      <TrainFlightTrainCard
                        key={index}
                        trainFlightTrain={trainFlightTrain}
                        fromCity={fromCity}
                        toCity={toCity}
                        getFares={getFares}
                      />
                    ))
                  ) : (
                    !loading && <div>No trainFlightTrain details available</div>
                  )
                ) : (
                  <div style={{ width: 600 }}>No Combinations found</div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
