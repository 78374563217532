import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ModesPage.css";
import Icon_x2 from "../assets/vectors1/Icon_x2.svg";
import DatePicker from "react-multi-date-picker";
import { useSelector, useDispatch } from "react-redux";

import {
  // setUpdatedData,
  setFromCity,
  setFromState,
  setToCity,
  setToState,
  setAdults,
  emptyDateWiseData,
  setDepartureDate,
  setData,
} from "../store/dataSlice";

import ModeCard from "../components/modecards";
import ModeCardstft from "../components/modecardstft";

import { formatDay, convertToMinutes } from "../utils/misc";

//TODO: add to utils
// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

//TODO: add to utils
const priorityList = ["SL", "CC", "3A", "2A", "1A"];

//TODO: add to utils
export function getAvailableClass(classesWithPrices) {
  for (let classType of priorityList) {
    if (classesWithPrices[classType] !== undefined) {
      return classesWithPrices[classType];
    }
  }

  return null;
}

//TODO: add to utils
export function getAvailableClassType(classesWithPrices) {
  for (let classType of priorityList) {
    if (classesWithPrices[classType] !== undefined) {
      return classType;
    }
  }

  return null;
}

const ModesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    fromCity: initialFromCity,
    fromState: initialFromState,
    toCity: initialToCity,
    toState: initialToState,
    departureDate: initialDepartureDate,
    adults: initialAdults,
    // flightData: initialFlightData,
    // trainData: initialTrainData,
    // trainTrainData: initialTrainTrainData,
    // flightTrainData: initialFlightTrainData,
    // trainFlightData: initialTrainFlightData,
    // trainFlightTrainData: initialtrainFlightTrainData,
  } = location.state || {};

  // const updatedData = useSelector((state) => state.data.updatedData);
  const fromCity = useSelector((state) => state.data.fromCity);
  // const fromState = useSelector((state) => state.data.fromState);
  const toCity = useSelector((state) => state.data.toCity);
  // const toState = useSelector((state) => state.data.toState);
  const departureDate = useSelector((state) => state.data.departureDate);
  const adults = useSelector((state) => state.data.adults);
  const dateWiseData = useSelector((state) => state.data.dateWiseData);

  const flightData = dateWiseData[departureDate]?.flightData ?? [];
  const trainData = dateWiseData[departureDate]?.trainData ?? [];
  // const trainTrainData = dateWiseData[departureDate]?.trainTrainData ?? [];
  // const flightTrainData = dateWiseData[departureDate]?.flightTrainData ?? [];
  // const trainFlightData = dateWiseData[departureDate]?.trainFlightData ?? [];
  // const trainFlightTraindata =
  //   dateWiseData[departureDate]?.trainFlightTraindata ?? [];

  const trainFlightIntermediateObject =
    dateWiseData[departureDate]?.trainFlightIntermediateObject ?? {};
  const flightTrainIntermediateObject =
    dateWiseData[departureDate]?.flightTrainIntermediateObject ?? {};
  const trainFlightTrainIntermediateObject =
    dateWiseData[departureDate]?.trainFlightTrainIntermediateObject ?? {};
  const trainTrainIntermediateObject =
    dateWiseData[departureDate]?.trainTrainIntermediateObject ?? {};

  const [adultsOpen, setAdultsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tempFromCity, setTempFromCity] = useState(initialFromCity);
  const [tempFromState, setTempFromState] = useState(initialFromState);
  const [tempToCity, setTempToCity] = useState(initialToCity);
  const [tempToState, setTempToState] = useState(initialToState);
  const [tempDepartureDate, setTempDepartureDate] =
    useState(initialDepartureDate);
  const [tempAdults, setTempAdults] = useState(initialAdults);

  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);

  const [initialTotal, setInitialTotal] = useState(0);

  // function initialTotal {
  //   return (
  //     (initialTrainData?.length > 0) +
  //     (initialFlightData?.length > 0) +
  //     (initialTrainFlightData?.length > 0) +
  //     (initialtrainFlightTrainData?.length > 0) +
  //     (initialFlightTrainData?.length > 0) +
  //     (initialTrainTrainData?.length > 0)
  //   );
  // }

  const handleDateChange = (e) => {
    const formatDate = new Date(e).toISOString().split("T")[0];
    setTempDepartureDate(formatDate);
    // setDateSelected(true);
  };
  const clearInput = (type) => {
    if (type === "from") {
      setTempFromCity("");
      setTempFromState("");
      setFromSuggestions([]);
    } else {
      setTempToCity("");
      setTempToState("");
      setToSuggestions([]);
    }
  };

  const fetchCitySuggestions = async (query, setSuggestions) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      console.log("calling for", query);
      const response = await fetch("https://wayport.in/cities/cities", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ start_letter: query }),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Debounced versions of fetchCitySuggestions
  const debouncedFetchFromCitySuggestions = useCallback(
    debounce((query) => fetchCitySuggestions(query, setFromSuggestions), 500),
    []
  );
  const debouncedFetchToCitySuggestions = useCallback(
    debounce((query) => fetchCitySuggestions(query, setToSuggestions), 500),
    []
  );
  const handleAdultsChange = (e) => {
    setTempAdults(e.target.value);
  };
  const handleSearch = async () => {
    dispatch(emptyDateWiseData());
    dispatch(setFromCity(tempFromCity));
    dispatch(setFromState(tempFromState));
    dispatch(setToCity(tempToCity));
    dispatch(setToState(tempToState));
    dispatch(setDepartureDate(tempDepartureDate));
    dispatch(setAdults(tempAdults));
    // const payload = {
    //   // queryStringParameters: {
    //   source_city: fromCity,
    //   desti_city: toCity,
    //   departure_date: departureDate,
    //   adults: adults,
    //   // },
    // };

    try {
      // console.log("Sending payload:", payload);
      setLoading(true);
      const response = await fetch(
        `https://wayport.in/api/test?source_city=${tempFromCity}&desti_city=${tempToCity}&source_state=${tempFromState}&desti_state=${tempToState}&departure_date=${tempDepartureDate}&adults=${tempAdults}`
        // `http://65.1.76.109:4000/routes?source_city=${fromCity}&desti_city=${toCity}&departure_date=${departureDate}&adults=${adults}`
        //   , {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(payload),
        // }
      );

      if (!response.ok) {
        setLoading(false);

        throw new Error("Network response was not ok");
      }
      setLoading(false);

      const data = await response.json();
      console.log("API response:", data);

      setInitialTotal(
        (data?.results?.direct_train?.length > 0) +
          (data?.results?.direct_flight?.length > 0) +
          (data?.results?.train_flight?.length > 0) +
          (data?.results?.train_flight_train?.length > 0) +
          (data?.results?.flight_train?.length > 0) +
          (data?.results?.train_train?.length > 0)
      );

      await updateDetails(data?.results);

      // navigate("/modes", {
      //   state: {
      //     fromCity: tempFromCity,
      //     fromState: tempFromState,
      //     toCity: tempToCity,
      //     toState: tempToState,
      //     adults: tempAdults,
      //     departureDate: tempDepartureDate,
      //     flightData: data?.results?.direct_flight ?? [],
      //     trainData: data?.results?.direct_train ?? [],
      //     trainTrainData: data?.results?.train_train,
      //     flightTrainData: data?.results?.flight_train,
      //     trainFlightData: data?.results?.train_flight,
      //     trainFlightTrainData: data?.results?.train_flight_train,
      //   },
      // });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      await updateDetails({});
      setLoading(false);
    }
  };

  const handleFromCityChange = (e) => {
    const query = e.target.value;
    setTempFromCity(query);
    if (query.length > 0) {
      debouncedFetchFromCitySuggestions(query);
    } else {
      setFromSuggestions([]);
    }
    if (query === null || query === "") {
      setTempFromState(null);
    }
  };

  const handleToCityChange = (e) => {
    const query = e.target.value;
    setTempToCity(query);
    if (query.length > 0) {
      debouncedFetchToCitySuggestions(query);
    } else {
      setToSuggestions([]);
    }
    if (query === null || query === "") {
      setTempToState(null);
    }
  };

  const selectCity = (city, field) => {
    if (field === "from") {
      setTempFromCity(city.city_name);
      setTempFromState(city.state);
      setFromSuggestions([]);
    } else if (field === "to") {
      setTempToCity(city.city_name);
      setTempToState(city.state);
      setToSuggestions([]);
    }
  };
  // function capitalizeFirstLetter(string) {
  //   if (!string) return "";
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  const handleViewDetails = (mode, intermediate = "none") => {
    console.log(intermediate);
    navigate("/details", {
      state: { mode, intermediate },
    });
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  const dummymode = false;

  async function updateDetails(data) {
    // dispatch(setDepartureDate(departureDate ?? (dummymode && "2024-08-24")));
    // dispatch(setFromCity(fromCity ?? (dummymode && "Source")));
    // dispatch(setFromState(fromState));
    // dispatch(setToCity(toCity ?? (dummymode && "Destination")));
    // dispatch(setToState(toState));
    // dispatch(setAdults(adults ?? (dummymode && "3")));

    dispatch(
      setData({
        type: "flight",
        data: dummymode
          ? [
              {
                type: "path",
                mode: "flight",
                properties: {
                  stop_count: 1,
                  segments: [
                    {
                      origin: {
                        id: "none",
                        name: "Chhatrapati Shivaji Maharaj International Airport",
                        display_code: "BOM",
                        city: "Mumbai",
                        terminal: "2",
                      },
                      destination: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      departure_time: 1724370900000,
                      arrival_time: 1724378100000,
                      duration: 120,
                      flight_name: "StarAir",
                      flight_number: "518",
                      logo_url: "none",
                      layover_time: 0,
                    },
                    {
                      origin: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      destination: {
                        id: "none",
                        name: "Indira Gandhi Airport",
                        display_code: "DEL",
                        city: "Delhi",
                        terminal: "3",
                      },
                      departure_time: 1724426400000,
                      arrival_time: 1724439000000,
                      duration: 210,
                      flight_name: "Indigo",
                      flight_number: "832",
                      logo_url: "none",
                      layover_time: 805,
                    },
                  ],
                  total_duration: 1135,
                  price: 166299,
                },
              },
              {
                type: "path",
                mode: "flight",
                properties: {
                  origin: {
                    id: "none",
                    name: "Nanded",
                    display_code: "NDC",
                    city: "Nanded",
                  },
                  destination: {
                    id: "none",
                    name: "Hindon Airport",
                    display_code: "HDO",
                    city: "Ghaziabad",
                  },
                  departure_time: 1725617700000,
                  arrival_time: 1725624900000,
                  duration: 120,
                  flight_name: "StarAir",
                  flight_number: "187",
                  stop_count: 0,
                  price: 5924,
                  tags: "none",
                  logo_url: "none",
                  alternate_id: "none",
                },
              },
              {
                type: "path",
                mode: "flight",
                properties: {
                  stop_count: 1,
                  segments: [
                    {
                      origin: {
                        id: "none",
                        name: "Chhatrapati Shivaji Maharaj International Airport",
                        display_code: "BOM",
                        city: "Mumbai",
                        terminal: "2",
                      },
                      destination: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      departure_time: 1724370900000,
                      arrival_time: 1724378100000,
                      duration: 120,
                      flight_name: "Air India",
                      flight_number: "518",
                      logo_url: "none",
                      layover_time: 0,
                    },
                    {
                      origin: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      destination: {
                        id: "none",
                        name: "Indira Gandhi Airport",
                        display_code: "DEL",
                        city: "Delhi",
                        terminal: "3",
                      },
                      departure_time: 1724426400000,
                      arrival_time: 1724439000000,
                      duration: 210,
                      flight_name: "Akasa Air",
                      flight_number: "832",
                      logo_url: "none",
                      layover_time: 805,
                    },
                  ],
                  total_duration: 1135,
                  price: 166299,
                },
              },
              {
                type: "path",
                mode: "flight",
                properties: {
                  stop_count: 1,
                  segments: [
                    {
                      origin: {
                        id: "none",
                        name: "Chhatrapati Shivaji Maharaj International Airport",
                        display_code: "BOM",
                        city: "Mumbai",
                        terminal: "2",
                      },
                      destination: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      departure_time: 1724370900000,
                      arrival_time: 1724378100000,
                      duration: 120,
                      flight_name: "Air India Express",
                      flight_number: "518",
                      logo_url: "none",
                      layover_time: 0,
                    },
                    {
                      origin: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      destination: {
                        id: "none",
                        name: "Indira Gandhi Airport",
                        display_code: "DEL",
                        city: "Delhi",
                        terminal: "3",
                      },
                      departure_time: 1724393100000,
                      arrival_time: 1724405100000,
                      duration: 200,
                      flight_name: "Vistara",
                      flight_number: "527",
                      logo_url: "none",
                      layover_time: 250,
                    },
                  ],
                  total_duration: 570,
                  price: 233244,
                },
              },
            ]
          : await withFlightNames(data?.direct_flight),
      })
    );
    dispatch(
      setData({
        type: "train",
        data: dummymode
          ? [
              {
                type: "path",
                mode: "train",
                properties: {
                  station_from: "J",
                  station_to: "SNSI",
                  departure_time: 1723606320000,
                  arrival_time: 1723626600000,
                  duration: "5:38",
                  train_number: "17208",
                  train_name: "Machilipatnam - Sainagar Shirdi Express",
                  from_day_count: 0,
                  to_day_count: 0,
                  class_type: ["SL", "3A", "2A", "1A"],
                  fare: 3415,
                },
              },
            ]
          : await withStationNames(data?.direct_train),
      })
    );
    dispatch(
      setData({
        type: "trainTrain",
        data: dummymode
          ? [
              {
                intermediate: "PURNA",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "NED",
                    station_to: "PAU",
                    departure_time: 1724302800000,
                    arrival_time: 1724304300000,
                    duration: "0:25",
                    train_number: "17663",
                    train_name: "RC PBN EXPRESS",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A"],
                    fare: 4907,
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "PAU",
                    station_to: "CSMT",
                    departure_time: 1724323080000,
                    arrival_time: 1724364480000,
                    duration: "11:30",
                    train_number: "17618",
                    train_name: "Tapovan Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "CC"],
                    fare: 1421,
                  },
                },
              },
              {
                intermediate: "PARBHANI",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "NED",
                    station_to: "PBN",
                    departure_time: 1724288100000,
                    arrival_time: 1724292480000,
                    duration: "1:13",
                    train_number: "12787",
                    train_name: "NARASAPUR - NAGARSOL SF Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                    fare: 2156,
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "PBN",
                    station_to: "PNVL",
                    departure_time: 1724354400000,
                    arrival_time: 1724402100000,
                    duration: "13:15",
                    train_number: "17614",
                    train_name: "Hazur Sahib Nanded - Panvel Express",
                    from_day_count: 0,
                    to_day_count: 1,
                    class_type: ["SL", "3A", "2A", "1A"],
                    fare: 1511,
                  },
                },
              },
            ]
          : await modifyTTData(data?.train_train),
      })
    );
    dispatch(
      setData({
        type: "flightTrain",
        data: dummymode
          ? [
              {
                intermediate: "NASHIK",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                    fare: 3704,
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Ozar Airport",
                      display_code: "ISK",
                      city: "Nasik",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723627200000,
                    arrival_time: 1723634100000,
                    duration: 115,
                    flight_name: "Indigo",
                    flight_number: "2037",
                    stop_count: 0,
                    price: 6688,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
              {
                intermediate: "SHIRDI",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                    fare: 3415,
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Shirdi airport",
                      display_code: "SAG",
                      city: "Shirdi",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723656300000,
                    arrival_time: 1723663800000,
                    duration: 125,
                    flight_name: "SpiceJet",
                    flight_number: "8947",
                    stop_count: 0,
                    price: 4743,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
            ]
          : await modifyTFData(data?.flight_train, "flightTrain"),
      })
    );
    dispatch(
      setData({
        type: "trainFlight",
        data: dummymode
          ? [
              {
                intermediate: "NASHIK",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                    fare: 3704,
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Ozar Airport",
                      display_code: "ISK",
                      city: "Nasik",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723627200000,
                    arrival_time: 1723634100000,
                    duration: 115,
                    flight_name: "Indigo",
                    flight_number: "2037",
                    stop_count: 0,
                    price: 6688,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
              {
                intermediate: "SHIRDI",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                    fare: 3415,
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Shirdi airport",
                      display_code: "SAG",
                      city: "Shirdi",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723656300000,
                    arrival_time: 1723663800000,
                    duration: 125,
                    flight_name: "SpiceJet",
                    flight_number: "8947",
                    stop_count: 0,
                    price: 4743,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
            ]
          : await modifyTFData(data?.train_flight, "trainFlight"),
      })
    );
    dispatch(
      setData({
        type: "trainFlightTrain",
        data: dummymode
          ? [
              {
                intermediate1: "NASHIK",
                intermediate2: "NASHIK2",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                    fare: 3704,
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                    fare: 3415,
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Ozar Airport",
                      display_code: "ISK",
                      city: "Nasik",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723627200000,
                    arrival_time: 1723634100000,
                    duration: 115,
                    flight_name: "Indigo",
                    flight_number: "2037",
                    stop_count: 0,
                    price: 6688,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
              {
                intermediate1: "SHIRDI",
                intermediate2: "SHIRDI2",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                    fare: 3415,
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                    fare: 3704,
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Shirdi airport",
                      display_code: "SAG",
                      city: "Shirdi",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723656300000,
                    arrival_time: 1723663800000,
                    duration: 125,
                    flight_name: "SpiceJet",
                    flight_number: "8947",
                    stop_count: 0,
                    price: 4743,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
            ]
          : await modifyTFTData(data?.train_flight_train, "trainFlightTrain"),
      })
    );
  }

  useEffect(() => {
    const searched = sessionStorage.getItem("searched");
    if (
      searched === "true" ||
      dummymode === true
      // fromCity !== initialFromCity ||
      // fromState !== initialFromState ||
      // toCity !== initialToCity ||
      // toState !== initialToState ||
      // departureDate !== initialDepartureDate ||
      // adults !== initialAdults
    ) {
      console.log("updating data");
      handleSearch();
      sessionStorage.setItem("searched", "false");
    } else {
      console.log("Same inputs so no need to update");
    }
  }, [location.state]);

  const totalPathsCount = () => {
    return (
      (trainData?.length > 0) +
      (flightData?.length > 0) +
      Object.keys(flightTrainIntermediateObject).length +
      Object.keys(trainFlightIntermediateObject).length +
      Object.keys(trainTrainIntermediateObject).length +
      Object.keys(trainFlightTrainIntermediateObject).length
    );
  };

  async function withFlightNames(flightData) {
    console.log(flightData);
    if (!flightData || flightData.length === 0 || "train" in flightData[0]) {
      console.log("No data available for flight.");
      return []; // Early exit if flightData is undefined or null
    }

    const newData = await Promise.all(
      flightData.map(async (combination, i) => {
        if (!combination.properties) {
          console.log(combination);
        }
        if (combination.properties && "segments" in combination.properties) {
          const newsegments = await Promise.all(
            combination.properties.segments.map(async (segment, si) => {
              const airportNames = await getAirportNames(
                segment.origin.display_code,
                segment.destination.display_code
              );

              return {
                ...segment,
                origin: {
                  ...segment.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...segment.destination,
                  name: airportNames.destination_airport_name,
                },
                origin_city: airportNames.origin_city,
                destination_city: airportNames.destination_city,
              };
            })
          );
          const flightPriceInNumber = combination.properties.price;

          return {
            ...combination,
            properties: {
              ...combination.properties,
              segments: newsegments,
            },
            total_duration: combination.properties.total_duration,
            total_price: flightPriceInNumber,
          };
        } else {
          const airportNames = await getAirportNames(
            combination.properties.origin.display_code,
            combination.properties.destination.display_code
          );

          const flightPriceInNumber = combination.properties.price;

          return {
            ...combination,
            properties: {
              ...combination.properties,
              origin: {
                ...combination.properties.origin,
                name: airportNames.origin_airport_name,
              },
              destination: {
                ...combination.properties.destination,
                name: airportNames.destination_airport_name,
              },
              origin_city: airportNames.origin_city,
              destination_city: airportNames.destination_city,
            },
            total_duration: combination.properties.duration,
            total_price: flightPriceInNumber,
          };
        }
      })
    );
    console.log(newData);

    return newData;
  }

  async function withStationNames(trainData) {
    console.log(trainData);
    if (!trainData || trainData.length === 0 || "flight" in trainData[0]) {
      console.log("No data available for flight.");
      return []; // Early exit if trainData is undefined or null
    }

    const newData = await Promise.all(
      trainData.map(async (combination, i) => {
        console.log(combination);

        const stationNames = await getStationNames(
          combination.properties.station_from,
          combination.properties.station_to
        );

        // const prices = await getFares(
        //   combination.properties.train_number,
        //   combination.properties.station_from,
        //   combination.properties.station_to
        // );
        // const trainFareInNumber = getAvailableClass(prices.general) || 0;
        const trainFareInNumber = 0;

        console.log(trainFareInNumber);

        return {
          ...combination,
          properties: {
            ...combination.properties,
            from_station_name: stationNames.origin_station_name,
            to_station_name: stationNames.destination_station_name,
            origin_city: stationNames.origin_city,
            destination_city: stationNames.destination_city,
            // prices: prices,
          },
          total_duration: convertToMinutes(combination.properties.duration),
          total_price: trainFareInNumber,
        };
      })
    );
    console.log(newData);

    return newData;
  }

  async function modifyTFTData(modeData, mode) {
    console.log(mode, modeData);
    if (!modeData) {
      console.log("No data available. for mode", mode);
      return; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          // const train1DurationInMinutes = convertToMinutes(
          //   combination.train1.properties.duration
          // );

          // const flightDurationInMinutes =
          //   combination.flight.properties.duration;

          // const train2DurationInMinutes = convertToMinutes(
          //   combination.train2.properties.duration
          // );
          // const buffer1 =
          //   (new Date(combination.flight.properties.departure_time) -
          //     new Date(combination.train1.properties.arrival_time)) /
          //   (1000 * 60);

          // const buffer2 =
          //   (new Date(combination.train2.properties.departure_time) -
          //     new Date(combination.flight.properties.arrival_time)) /
          //   (1000 * 60);

          // const totalDurationInMinutes =
          //   train1DurationInMinutes +
          //   train2DurationInMinutes +
          //   flightDurationInMinutes +
          //   buffer1 +
          //   buffer2;

          // console.log(totalDurationInMinutes);

          // const flightPriceInNumber = combination.flight.properties.price;

          // const prices1 = await getFares(
          //   combination.train1.properties.train_number,
          //   combination.train1.properties.station_from,
          //   combination.train1.properties.station_to
          // );
          // const prices2 = await getFares(
          //   combination.train2.properties.train_number,
          //   combination.train2.properties.station_from,
          //   combination.train2.properties.station_to
          // );

          // console.log(prices1, prices2);

          // const train1FareInNumber = getAvailableClass(prices1.general) || 0;
          // const train2FareInNumber = getAvailableClass(prices2.general) || 0;
          // const train1FareInNumber = 0;
          // const train2FareInNumber = 0;

          // const totalPrice =
          //   train1FareInNumber + train2FareInNumber + flightPriceInNumber;

          // console.log(totalPrice);

          const station1Names = await getStationNames(
            combination.train1.properties.station_from,
            combination.train1.properties.station_to
          );

          const station2Names = await getStationNames(
            combination.train2.properties.station_from,
            combination.train2.properties.station_to
          );

          const airportNames = await getAirportNames(
            combination.flight.properties.origin.display_code,
            combination.flight.properties.destination.display_code
          );

          return {
            ...combination,
            // total_duration: totalDurationInMinutes,
            train1: {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                // prices: prices1,
                from_station_name: station1Names.origin_station_name,
                to_station_name: station1Names.destination_station_name,
                origin_city: station1Names.origin_city,
                destination_city: station1Names.destination_city,
              },
            },
            train2: {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                // prices: prices2,
                from_station_name: station2Names.origin_station_name,
                to_station_name: station2Names.destination_station_name,
                origin_city: station2Names.origin_city,
                destination_city: station2Names.destination_city,
              },
            },
            flight: {
              ...combination.flight,
              properties: {
                ...combination.flight.properties,
                origin: {
                  ...combination.flight.properties.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...combination.flight.properties.destination,
                  name: airportNames.destination_airport_name,
                },
                origin_city: airportNames.origin_city,
                destination_city: airportNames.destination_city,
              },
            },
            // total_price: totalPrice,
          };
        })
      );

      return newData;
    } catch (error) {
      console.error("Error processing data:", error.message);
      return modeData;
    }
  }

  async function modifyTFData(modeData, mode) {
    console.log(mode, modeData);
    if (!modeData) {
      console.log("No data available. for mode", mode);
      return; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const trainDurationInMinutes = convertToMinutes(
            combination.train.properties.duration
          );

          const flightDurationInMinutes =
            combination.flight.properties.duration;

          const buffer =
            mode == "flightTrain"
              ? Math.abs(
                  (new Date(combination.train.properties.departure_time) -
                    new Date(combination.flight.properties.arrival_time)) /
                    (1000 * 60)
                )
              : Math.abs(
                  (new Date(combination.flight.properties.departure_time) -
                    new Date(combination.train.properties.arrival_time)) /
                    (1000 * 60)
                );

          const totalDurationInMinutes =
            trainDurationInMinutes + flightDurationInMinutes + buffer;

          // const flightPriceInNumber = parseInt(
          //   combination.flight.properties.price.replace(/[^\d]/g, ""),
          //   10
          // );

          const flightPriceInNumber = combination.flight.properties.price;

          // const prices = await getFares(
          //   combination.train.properties.train_number,
          //   combination.train.properties.station_from,
          //   combination.train.properties.station_to
          // );
          // const trainFareInNumber = getAvailableClass(prices.general) || 0;
          const trainFareInNumber = 0;

          const totalPrice = trainFareInNumber + flightPriceInNumber;

          const stationNames = await getStationNames(
            combination.train.properties.station_from,
            combination.train.properties.station_to
          );

          const airportNames = await getAirportNames(
            combination.flight.properties.origin.display_code,
            combination.flight.properties.destination.display_code
          );

          console.log("flights", airportNames);

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            train: {
              ...combination.train,
              properties: {
                ...combination.train.properties,
                from_station_name: stationNames.origin_station_name,
                to_station_name: stationNames.destination_station_name,
                origin_city: stationNames.origin_city,
                destination_city: stationNames.destination_city,
                // prices: prices,
              },
            },
            flight: {
              ...combination.flight,
              properties: {
                ...combination.flight.properties,
                origin: {
                  ...combination.flight.properties.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...combination.flight.properties.destination,
                  name: airportNames.destination_airport_name,
                },
                origin_city: airportNames.origin_city,
                destination_city: airportNames.destination_city,
              },
            },
            total_price: totalPrice,
          };
        })
      );

      return newData;
    } catch (error) {
      console.error("Error processing data:", error);
      return modeData;
    }
  }

  async function modifyTTData(modeData) {
    console.log(modeData);
    if (!modeData) {
      console.log("No data available. for ttmode");
      return; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const train1DurationInMinutes = convertToMinutes(
            combination.train1.properties.duration
          );

          const train2DurationInMinutes = convertToMinutes(
            combination.train2.properties.duration
          );

          const buffer = Math.abs(
            (new Date(combination.train2.properties.departure_time) -
              new Date(combination.train1.properties.arrival_time)) /
              (1000 * 60)
          );

          const totalDurationInMinutes =
            train1DurationInMinutes + train2DurationInMinutes + buffer;

          const train1FareInNumber = 0;
          const train2FareInNumber = 0;

          const totalPrice = train1FareInNumber + train2FareInNumber;
          const stationNames1 = await getStationNames(
            combination.train1.properties.station_from,
            combination.train1.properties.station_to
          );
          const stationNames2 = await getStationNames(
            combination.train2.properties.station_from,
            combination.train2.properties.station_to
          );

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            total_price: totalPrice,
            train1: {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                from_station_name: stationNames1.origin_station_name,
                to_station_name: stationNames1.destination_station_name,
                origin_city: stationNames1.origin_city,
                destination_city: stationNames1.destination_city,
              },
            },
            train2: {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                from_station_name: stationNames2.origin_station_name,
                to_station_name: stationNames2.destination_station_name,
                origin_city: stationNames2.origin_city,
                destination_city: stationNames2.destination_city,
              },
            },
          };
        })
      );

      console.log(newData);

      return newData;
    } catch (error) {
      console.error("Error processing data:", error);
      return modeData;
    }
  }

  let trainFares = {};

  async function getFares(train_number, fromStation, toStation) {
    if (dummymode) {
      console.log("returned dummy fares");
      return {
        general: {
          AC1: 1000,
          AC2: 1000,
          AC3: 1000,
          AC4: 1000,
          AC: 1000,
          CC: 1000,
          SL: 1000,
        },
        tatkal: {
          AC1: 1000,
          AC2: 1000,
          AC3: 1000,
          AC4: 1000,
          AC: 1000,
          CC: 1000,
          SL: 1000,
        },
      };
    }
    try {
      if (trainFares[train_number]) {
        const result = trainFares[train_number];

        let generalprices = {};
        let tatkalprices = {};

        result.data?.general.forEach((fare, i) => {
          generalprices[fare.classType] = fare.fare;
        });
        result.data?.tatkal.forEach((fare, i) => {
          tatkalprices[fare.classType] = fare.fare;
        });

        const prices = {
          general: generalprices,
          tatkal: tatkalprices,
        };

        return prices;
      } else {
        const url = `https://irctc1.p.rapidapi.com/api/v2/getFare?trainNo=${train_number}&fromStationCode=${fromStation}&toStationCode=${toStation}`;
        const options = {
          method: "GET",
          headers: {
            "x-rapidapi-key": process.env.REACT_APP_API_KEY,
            "x-rapidapi-host": "irctc1.p.rapidapi.com",
          },
        };

        const response = await fetch(url, options);
        const result = await response.json();
        console.log(result);

        let generalprices = {};
        let tatkalprices = {};

        result.data?.general.forEach((fare, i) => {
          generalprices[fare.classType] = fare.fare;
        });
        result.data?.tatkal.forEach((fare, i) => {
          tatkalprices[fare.classType] = fare.fare;
        });

        const prices = {
          general: generalprices,
          tatkal: tatkalprices,
        };

        return prices;
      }
    } catch (error) {
      console.error(error);
    }
  }

  let airportNamesByCode = {};
  let cityNameAirByCode = {};
  async function getAirportNames(fromCode, toCode) {
    try {
      if (dummymode) {
        return {
          origin_station_name: "Failed to fetch",
          destination_station_name: "Failed to fetch",
        };
      }
      // 65.1.76.109
      if (airportNamesByCode[fromCode] && airportNamesByCode[toCode]) {
        console.log("found already existed for", fromCode, toCode);
        return {
          origin_airport_name: airportNamesByCode[fromCode],
          destination_airport_name: airportNamesByCode[toCode],
          origin_city: cityNameAirByCode[fromCode],
          destination_city: cityNameAirByCode[toCode],
        };
      } else {
        const response = await fetch("https://wayport.in/api/airport", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            origin_airport_code: fromCode,
            destination_airport_code: toCode,
          }),
        });
        const result = await response.json();

        airportNamesByCode[fromCode] = result.origin_airport_name;
        airportNamesByCode[toCode] = result.destination_airport_name;
        cityNameAirByCode[fromCode] = result.origin_city;
        cityNameAirByCode[toCode] = result.destination_city;
        console.log(result.origin_city);
        console.log(result.destination_city);
        return result;
      }
    } catch (error) {
      console.error(error);
      return {
        origin: { airport_name: "Failed to fetch" },
        destination: { airport_name: "Failed to fetch" },
      };
    }
  }

  let stationNamesByCode = {};
  let cityNameByCode = {};
  //TODO: Optimise later by taking only one station at a time.
  async function getStationNames(fromCode, toCode) {
    try {
      if (dummymode) {
        return {
          origin_station_name: "Failed to fetch",
          destination_station_name: "Failed to fetch",
        };
      }

      if (stationNamesByCode[fromCode] && stationNamesByCode[toCode]) {
        console.log("found already existed for", fromCode, toCode);
        return {
          origin_station_name: stationNamesByCode[fromCode],
          destination_station_name: stationNamesByCode[toCode],
          origin_city: cityNameByCode[fromCode],
          destination_city: cityNameByCode[toCode],
        };
      } else {
        const response = await fetch("https://wayport.in/api/station", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            origin_station_code: fromCode,
            destination_station_code: toCode,
          }),
        });
        const result = await response.json();
        console.log(result);

        stationNamesByCode[fromCode] = result.origin_station_name;
        stationNamesByCode[toCode] = result.destination_station_name;
        cityNameByCode[fromCode] = result.origin_city;
        cityNameByCode[toCode] = result.destination_city;
        return result;
      }
    } catch (error) {
      console.error(error);
      return {
        origin_station_name: "Failed to fetch",
        destination_station_name: "Failed to fetch",
      };
    }
  }

  //////////////////////////////////////////main return part ////////////////////////////////

  // Object.values(trainFlightTrainIntermediateObject).map((value) => {
  //     console.log(value);
  // });

  return (
    <div className="mixed">
      {/* <p className="w-screen">{JSON.stringify(dateWiseData)}</p> */}
      {/* {loading && (
        <div className="absolute bg-white top-0 left-0 h-screen w-screen flex justify-center items-center z-30 text-4xl">
          Loading...
        </div>
      )} */}
      <div className="main-frame">
        <div className="input-container">
          <div className="input-upper">
            <div className="left-container">
              <div className="from-input">
                <div className="from">
                  <span className="from-1">From</span>
                </div>
                <div className="city-name">
                  <div className="city">
                    <input
                      className="vijayawada"
                      value={tempFromCity}
                      onChange={handleFromCityChange}
                      type="text"
                      placeholder="Departure City"
                    />
                    {tempFromCity && (
                      <span
                        className="clear-cross"
                        onClick={() => clearInput("from")}
                      >
                        ×
                      </span>
                    )}
                    {fromSuggestions.length > 0 && (
                      <div className="suggestions">
                        <div className="top-search-1">Top searches</div>
                        {fromSuggestions.map((city, index) => (
                          <div
                            key={index}
                            className="suggestion"
                            onClick={() => selectCity(city, "from")}
                          >
                            {city.city_name}
                            <div className="state-display">
                              {city.state}, India
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {tempFromState && (
                    <div className="state">
                      <span className="telangana-india">
                        {tempFromCity && tempFromState + ", India"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="from-to">
                <img className="icon" src={Icon_x2} alt="" />
              </div>
            </div>
            <div className="to-input">
              <div className="from-2">
                <span className="to">To</span>
              </div>
              <div className="city-name-1">
                <div className="city-1">
                  <input
                    className="lucknow"
                    value={tempToCity}
                    onChange={handleToCityChange}
                    type="text"
                    placeholder="Destination City"
                  />
                  {tempToCity && (
                    <span className="clear-cross" onClick={clearInput}>
                      ×
                    </span>
                  )}
                  {toSuggestions.length > 0 && (
                    <div className="suggestions-1">
                      <div className="top-search-2">Top searches</div>
                      {toSuggestions.map((city, index) => (
                        <div
                          key={index}
                          className="suggestion-1"
                          onClick={() => selectCity(city, "to")}
                        >
                          {city.city_name}
                          <div className="state-display-1">
                            {city.state}, India
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {tempToState && (
                  <div className="state-1">
                    <span className="uttar-pradesh-india">
                      {tempToCity && tempToState + ", India"}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="departure-input">
              <div className="from-3">
                <span className="departure">Departure</span>
              </div>
              <div className="city-name-2">
                <div className="city-2">
                  <div className="dateinput">
                    <DatePicker
                      format="D MMMM YYYY"
                      style={{
                        width: "100%",
                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: 20,
                        padding: 0,
                        letterSpacing: "-0.4px",
                        margin: 0,
                        outline: "none",
                        boxShadow: "none",
                        color: "black",
                        background: "none",
                        cursor: "pointer",
                      }}
                      // range

                      numberOfMonths={2}
                      value={new Date(tempDepartureDate)}
                      onChange={handleDateChange}
                      className="teal"
                    />
                  </div>
                </div>
                <div className="state-2">
                  <span className="wednesday">
                    {formatDay(tempDepartureDate)}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="adults-input"
              onClick={() => {
                setAdultsOpen(!adultsOpen);
              }}
            >
              <div className="from-4">
                <span className="travellers">Travellers</span>
              </div>
              <div className="city-name-3">
                <div className="city-3">
                  <span className="traveller">
                    {tempAdults === 1 ? (
                      <>{tempAdults} Traveller</>
                    ) : (
                      <>{tempAdults} Travellers</>
                    )}
                  </span>
                </div>
                <div className="state-3">
                  <span className="adult">
                    {tempAdults === 1 ? (
                      <>{tempAdults} Adult</>
                    ) : (
                      <>{tempAdults} Adults</>
                    )}
                  </span>
                </div>
              </div>
            </div>
            {adultsOpen && (
              <div className="adult-pop">
                <div className="containerx">
                  <p className="title">Adults</p>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        setTempAdults(tempAdults > 0 ? tempAdults - 1 : 0); // Ensure adults don't go negative
                      }}
                      className="button-top"
                    >
                      -
                    </button>
                    <p className="adult-count">
                      {tempAdults.toString().padStart(2, "0")}
                    </p>
                    <button
                      onClick={() => {
                        setAdults(tempAdults + 1);
                      }}
                      className="button-top"
                    >
                      +
                    </button>
                    <p className="adult-info">16+ years</p>
                  </div>

                  <div className="footer">
                    <button
                      onClick={() => {
                        setAdultsOpen(!adultsOpen);
                      }}
                      className="done-button"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="btn-wrap">
            <div className="just-type-in-city-name"></div>
            <div className="search-button" onClick={handleSearch}>
              <span className="search">Search</span>
            </div>
          </div>
        </div>
        <div className="cards-cont">
          {loading ? (
            <div className="skeleton w-[300px] min-w-[300px] min-h-[28px] mb-11 h-[32px]"></div>
          ) : (
            <div className="paths-count">
              <span className="paths-for-you-to-travel">
                {totalPathsCount()} {totalPathsCount() <= 1 ? "Path" : "Paths"}{" "}
                for you to travel
              </span>
            </div>
          )}

          {loading ? (
            <>
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <div
                    key={i}
                    className="skeleton w-full min-h-[80px] mb-10 h-[80px]"
                  ></div>
                ))}
            </>
          ) : (
            initialTotal == 0 &&
            totalPathsCount() == 0 && (
              <div className="flex flex-col w-full items-center">
                <dotlottie-player
                  direction="1"
                  id="animation_map-with-pulsing-city-dots_e16d5fea-1165-11ee-8615-6bd07bc9a3fb"
                  speed="1"
                  mode="normal"
                  src="https://lottie.host/300b37f9-1a81-4db6-9d6d-f77c44a9ed3c/qrVRUnQ46W.json"
                  style={{
                    width: "200px",
                    margin: "-50px",
                    zIndex: 0,
                    height: "200px",
                  }}
                  autoplay
                  loop
                ></dotlottie-player>
                <p className="z-10 text-lg font-semibold text-balance text-center">
                  Sorry, we couldn't find any results for your search based on
                  available trains, flights, or combinations. Please try
                  searching with a different date.
                </p>
              </div>
            )
          )}

          {!loading && trainData?.length > 0 && (
            <ModeCard
              mode="train"
              index={0}
              data={trainData}
              fromCity={fromCity}
              midCity={toCity}
              toCity={toCity}
              departureDate={departureDate}
              adults={adults}
              handleViewDetails={handleViewDetails}
            />
          )}
          {!loading && flightData?.length > 0 && (
            <ModeCard
              mode="flight"
              index={1}
              data={flightData}
              fromCity={fromCity}
              midCity={toCity}
              toCity={toCity}
              departureDate={departureDate}
              adults={adults}
              handleViewDetails={handleViewDetails}
            />
          )}
          {!loading &&
            Object.keys(flightTrainIntermediateObject).map((key, index) => (
              <ModeCard
                key={index}
                mode="flightTrain"
                index={index}
                data={flightTrainIntermediateObject[key]}
                fromCity={fromCity}
                midCity={key}
                toCity={toCity}
                departureDate={departureDate}
                adults={adults}
                handleViewDetails={handleViewDetails}
              />
            ))}

          {!loading &&
            Object.keys(trainFlightIntermediateObject).map((key, index) => (
              <ModeCard
                key={index}
                mode="trainFlight"
                index={index}
                data={trainFlightIntermediateObject[key]}
                fromCity={fromCity}
                midCity={key}
                toCity={toCity}
                departureDate={departureDate}
                adults={adults}
                handleViewDetails={handleViewDetails}
              />
            ))}

          {!loading &&
            Object.keys(trainTrainIntermediateObject).map((key, index) => (
              <ModeCard
                key={index}
                mode="trainTrain"
                index={index}
                data={trainTrainIntermediateObject[key]}
                fromCity={fromCity}
                midCity={key}
                toCity={toCity}
                departureDate={departureDate}
                adults={adults}
                handleViewDetails={handleViewDetails}
              />
            ))}
          {!loading &&
            Object.keys(trainFlightTrainIntermediateObject).map(
              (key, index) => (
                <ModeCardstft
                  key={index}
                  mode="trainFlightTrain"
                  index={index}
                  data={trainFlightTrainIntermediateObject[key]}
                  fromCity={fromCity}
                  midCity={key}
                  toCity={toCity}
                  departureDate={departureDate}
                  adults={adults}
                  handleViewDetails={handleViewDetails}
                />
              )
            )}
          {Array(Math.max(initialTotal - totalPathsCount(), 0))
            .fill(1)
            .map((_, i) => (
              <div key={i} className="skeleton w-full h-[80px] mb-10"></div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ModesPage;
