import DateSVG from "../assets/vectors/Vector1018_x2.svg";
import Arrow from "../assets/vectors/Icon13_x2.svg";
import Train from "../assets/vectors/Vector37_x2.svg";
import Plane from "../assets/images/flight.png";
import infoicon from "../assets/vectors/BxInfoCircle1_x2.svg";
import Leftarrow from "../assets/vectors/Vector101_x2.svg";
import Rightarrow from "../assets/vectors/Vector103_x2.svg";
import DatePicker from "react-multi-date-picker";
import { useState } from "react";

export default function Headertft({
  fromCity,
  toCity,
  intermediate,
  intermediate2,
  mode,
  departureDate,
}) {
  const [date, setDate] = useState(new Date(departureDate));

  const incrementDate = () => {
    setDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() + 1)));
  };

  const decrementDate = () => {
    setDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() - 1)));
  };
  if (intermediate) {
    intermediate =
      intermediate.charAt(0).toUpperCase() +
      intermediate.substring(1)?.toLowerCase();
  }

  if (intermediate2) {
    intermediate2 =
      intermediate2.charAt(0).toUpperCase() +
      intermediate2.substring(1)?.toLowerCase();
  }
  return (
    <>
      <div className="tfsearchcard w-[1200px]">
        <div className="header-vector">
          <div className="two-modes-cards-3">
            <span className="vijayawada-1 capitalize">{fromCity?.toLowerCase()}</span>
          </div>

          <div className="first-vector">
            <div className="line-1"></div>
            <div className="frame-2">
              <img className="vector-35" src={Train} alt="" />
              {/* <Train /> */}
            </div>
            <div className="line-2"></div>
          </div>

          <div className="two-modes-cards-4">
            <span className="hyderabad-1 capitalize">
              {/* {modeData[0].intermediate} */}
              {intermediate?.toLowerCase()}
            </span>
          </div>
          <div className="first-vector">
            <div className="line-1"></div>
            <div className="frame-2">
              {mode === "trainFlight" || mode === "trainFlightTrain" ? (
                <img className="vector-35" src={Plane} />
              ) : (
                <img className="vector-35" src={Train} />
              )}
            </div>
            <div className="line-2"></div>
          </div>

          <div className="two-modes-cards-4">
            <span className="hyderabad-1 capitalize">
              {/* {modeData[0].intermediate} */}
              {intermediate2?.toLowerCase()}
            </span>
          </div>
          <div className="first-vector">
            <div className="line-1"></div>
            <div className="frame-2">
              <img className="vector-35" src={Train} />
            </div>
            <div className="line-2"></div>
          </div>
          <div className="two-modes-cards-5">
            <span className="lucknow-1 capitalize">{toCity?.toLowerCase()}</span>
          </div>
        </div>
        <div
        className="frame-5131-tft"
      >
        <div className="frame-5121">
          <div className="arrow-drop-left-8" onClick={decrementDate}>
            <img className="vector-1016" src={Leftarrow} />
          </div>
          <div className="frame-3101">
            <span className="may-20241">
            {departureDate}
              {/* <DatePicker
                format="D MMMM YYYY"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  fontFamily: "Microsoft Sans Serif ,Roboto Condensed",
                  fontSize: 18,
                  padding: 0,
                  letterSpacing: "-0.4px",
                  margin: 0,
                  outline: "none",
                  boxShadow: "none",
                  textAlign: "center",
                }}
                numberOfMonths={2}
                // value={new Date(departureDate)}
                value={date}
                className="teal"
                onChange={setDate}
              /> */}
            </span>
          </div>
          <div className="arrow-drop-right-8" onClick={incrementDate}>
            <img className="vector-1017" src={Rightarrow} />
          </div>
        </div>
      </div>
      </div>
      
    </>
  );
}
