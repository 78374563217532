import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export interface CounterState {
  updatedData: boolean;
  fromCity: string;
  fromState: string;
  toCity: string;
  toState: string;
  adults: number;
  departureDate: string;
  // flightData: Array<Object>;
  // trainData: Array<Object>;
  // trainTrainData: Array<Object>;
  // trainFlightTrainData: Array<Object>;
  // trainFlightTrainIntermediateObject: Object;
  // flightTrainData: Array<Object>;
  // trainFlightData: Array<Object>;
  // flightTrainIntermediateObject: Object;
  // trainFlightIntermediateObject: Object;
  openlogin: boolean;

  dateWiseData: dateWiseObject;
}

interface dateWiseObject {
  [key: string]: {
    flightData?: Array<Object>;
    trainData?: Array<Object>;
    trainTrainData?: Array<Object>;
    trainFlightTrainData?: Array<Object>;
    trainFlightTrainIntermediateObject?: Object;
    flightTrainData?: Array<Object>;
    trainFlightData?: Array<Object>;
    flightTrainIntermediateObject?: Object;
    trainFlightIntermediateObject?: Object;
    trainTrainIntermediateObject?: Object;
  };
}

interface DataAction {
  type: string;
  data: Array<CombinationObject>;
  date?: string;
}

const initialState: CounterState = {
  updatedData: false,
  fromCity: "",
  openlogin: false,
  fromState: "",
  toCity: "",
  toState: "",
  adults: 1,
  departureDate: "",
  // flightData: [],
  // trainData: [],
  // trainTrainData: [],
  // flightTrainData: [],
  // trainFlightData: [],
  // trainFlightTrainData: [],
  // flightTrainIntermediateObject: {},
  // trainFlightIntermediateObject: {},
  // trainFlightTrainIntermediateObject: {},

  dateWiseData: {},
};

export const counterSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },
    setUpdatedData: (state, action: PayloadAction<boolean>) => {
      state.updatedData = action.payload;
    },
    setFromCity: (state, action: PayloadAction<string>) => {
      state.fromCity = action.payload;
    },
    setOpenlogin: (state, action: PayloadAction<boolean>) => {
      state.openlogin = action.payload;
    },
    setFromState: (state, action: PayloadAction<string>) => {
      state.fromState = action.payload;
    },
    setToCity: (state, action: PayloadAction<string>) => {
      state.toCity = action.payload;
    },
    setToState: (state, action: PayloadAction<string>) => {
      state.toState = action.payload;
    },
    setAdults: (state, action: PayloadAction<number>) => {
      state.adults = action.payload;
    },
    setDepartureDate: (state, action: PayloadAction<string>) => {
      state.departureDate = action.payload;
    },
    emptyDateWiseData: (state) => {
      state.dateWiseData = {};
    },
    setData: (state, action: PayloadAction<DataAction>) => {
      console.log(state.departureDate);
      if (!state.dateWiseData[action.payload.date ?? state.departureDate]) {
        state.dateWiseData[action.payload.date ?? state.departureDate] = {};
      }
      switch (action.payload.type) {
        case "flight":
          state.dateWiseData[
            action.payload.date ?? state.departureDate
          ].flightData = action.payload.data;
          break;
        case "train":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainData"
          ] = action.payload.data;
          break;
        case "trainTrain":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainTrainData"
          ] = action.payload.data;
           state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainTrainIntermediateObject"
          ] = createIntermediateObject(action.payload.data);

          break;
        case "flightTrain":
          
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "flightTrainData"
          ] = action.payload.data;

          state.dateWiseData[action.payload.date ?? state.departureDate][
            "flightTrainIntermediateObject"
          ] = createIntermediateObject(action.payload.data);

          break;
        case "trainFlight":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightData"
          ] = action.payload.data;

          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightIntermediateObject"
          ] = createIntermediateObject(action.payload.data);

          break;
        case "trainFlightTrain":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightTrainData"
          ] = action.payload.data;

          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightTrainIntermediateObject"
          ] = createIntermediateTFTObject(
            //@ts-ignore
            action.payload.data
          );

          break;
        default:
          break;
      }
    },

    // updateFlightTrainIntermediateObject: (state, action: PayloadAction<Object>) => {
    //  state.flightTrainIntermediateObject = action.payload;
    // },
    // updateTrainFlightIntermediateObject: (state, action: PayloadAction<Object>)=> {
    // state.trainFlightIntermediateObject = action.payload;
    // },
  },
});

interface CombinationObject {
  intermediate: string;
  train: Array<Object>;
  flight: Array<Object>;
}

interface CombinationTFTObject {
  intermediate1: string;
  train1: Array<Object>;
  flight: Array<Object>;
  intermediate2: string;
  train2: Array<Object>;
}
interface IntermediteObjectType {
  [key: string]: Array<CombinationObject>;
}

interface IntermediteTFTObjectType {
  [key: string]: Array<CombinationTFTObject>;
}

const createIntermediateObject = (data: Array<CombinationObject>) => {
  const IntermediteObject: IntermediteObjectType = {};

  data?.forEach((obj) => {
    const intermediate = obj.intermediate;

    if (!IntermediteObject[intermediate]) {
      IntermediteObject[intermediate] = [];
    }

    IntermediteObject[intermediate].push(obj);
  });

  return IntermediteObject;
};

const createIntermediateTFTObject = (data: Array<CombinationTFTObject>) => {
  const IntermediteObject: IntermediteTFTObjectType = {};

  data?.forEach((obj) => {
    const intermediate1 = obj.intermediate1;
    const intermediate2 = obj.intermediate2;
    const intermediate = intermediate1 + "&" + intermediate2;
    if (!IntermediteObject[intermediate]) {
      IntermediteObject[intermediate] = [];
    }
    IntermediteObject[intermediate].push(obj);
  });

  return IntermediteObject;
};

// Action creators are generated for each case reducer function
export const {
  setUpdatedData,
  setFromCity,
  setFromState,
  setToCity,
  setToState,
  setAdults,
  setDepartureDate,
  setData,
  emptyDateWiseData,
  setOpenlogin,
  // updateFlightTrainIntermediateObject,
  // updateTrainFlightIntermediateObject
} = counterSlice.actions;

export default counterSlice.reducer;
