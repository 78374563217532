import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import Router from './Routes/Router';


// import LandingPage from './pages/LandingPage';
// import ModesPage from './pages/ModesPage';
// import TfcardsPage from './pages/CardsPage';


// const router = createBrowserRouter([
//   // { path: '/', element: <F12Main /> },
// { path: '/', element: <LandingPage /> },
// { path: '/modes', element: <ModesPage /> },
// { path: '/details', element: <TfcardsPage /> },
// ]);

// export default function App() {
//   return (
//     <RouterProvider router={router} />
//   );
// }
import React from 'react'

export default function App() {
  return (
    <div><Router/></div>
  )
}
