import Trainy from "../assets/vectors1/Trainy.svg";
import Plane from "../assets/vectors1/Plane.svg";
import Adcount from "../assets/vectors1/Adcount.svg";
import Next from "../assets/vectors/Vector9_x2.svg";

export default function ModeCard({
  mode,
  index,
  data,
  fromCity,
  midCity,
  toCity,
  departureDate,
  adults,
  handleViewDetails,
}) {
  const findMinPrice = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        currentItem.total_price < minItem.total_price ? currentItem : minItem
      )
    );
  };

  const findMinTime = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        currentItem.total_duration < minItem.total_duration
          ? currentItem
          : minItem
      )
    );
  };

  const minDate = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        (
          mode == "train"
            ? currentItem.properties.arrival_time <
              minItem.properties.arrival_time
            : mode == "flight" && minItem.properties.stop_count === 0
            ? currentItem.properties.arrival_time <
              minItem.properties.arrival_time
            : mode == "flight" && minItem.properties.stop_count >= 1
            ? currentItem.properties.segments?.[
                currentItem.properties.segments?.length - 1
              ]?.arrival_time <
              minItem.properties.segments?.[
                minItem.properties.segments?.length - 1
              ]?.arrival_time
            : mode == "trainFlight"
            ? currentItem.flight.properties.arrival_time <
              minItem.flight.properties.arrival_time
            : mode == "flightTrain"
            ? currentItem.train.properties.arrival_time <
              minItem.train.properties.arrival_time
            : currentItem.train2.properties.arrival_time <
              minItem.train2.properties.arrival_time
        )
          ? currentItem
          : minItem
      )
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.toLocaleDateString("en-US", { weekday: "short" });
    const month = date.toLocaleString("default", { month: "short" });

    const dayOfMonth = date.getDate();
    const year = date.getFullYear();

    const nth = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}, ${dayOfMonth}${nth(dayOfMonth)} ${month}`;
  };

  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const minData = findMinPrice(data);
  const minTimeData = findMinTime(data);
  const minDateData = minDate(data);

  return (
    <div key={index} className="f-2">
      <div className="flight-details">
        <div className="mode-main">
          <div className="single-city">
            <div className="city-name-8">
              <span className="vijayawada-6 capitalize">
                {mode === "train"
                  ? minData.properties.origin_city?.toLowerCase()
                  : mode === "flight" && minData.properties.stop_count >= 1
                  ? minData.properties.segments[0].origin_city?.toLowerCase()
                  : mode === "flight" && minData.properties.stop_count === 0
                  ? minData.properties.origin_city?.toLowerCase()
                  : mode === "trainFlight"
                  ? minData.train.properties.origin_city?.toLowerCase()
                  : mode === "flightTrain"
                  ? minData.flight.properties.origin.city
                  : mode === "trainFlightTrain" || mode === "trainTrain"
                  ? minData.train1.properties.origin_city?.toLowerCase()
                  : { fromCity }}
              </span>
            </div>
            <div className="day-date-4">
              <span className="wed-15-may-4">{formatDate(departureDate)}</span>
            </div>
          </div>
          <div className="mode-icon">
            <div className="dotted-line"></div>
            <div className="frame-1">
              <img
                className="vector-6"
                src={mode == "flight" || mode == "flightTrain" ? Plane : Trainy}
              />
            </div>
            <div className="dotted-line-1"></div>
          </div>
          <div className="single-city">
            <div className="city-name-8">
              <span className="vijayawada-7 capitalize">
                {mode === "train"
                  ? minData.properties.destination_city?.toLowerCase()
                  : mode === "flight" && minData.properties.stop_count >= 1
                  ? minData.properties.segments[
                      minData.properties?.segments?.length - 1
                    ].destination_city?.toLowerCase()
                  : mode === "flight" && minData.properties.stop_count === 0
                  ? minData.properties.destination_city?.toLowerCase()
                  : mode === "trainFlight" ||
                    mode === "trainTrain" ||
                    mode === "flightTrain"
                  ? minData.intermediate?.toLowerCase()
                  : { fromCity }}
              </span>
            </div>
            <div className="day-date-4">
              <span className="wed-15-may-5">
                {formatDate(
                  mode == "train"
                    ? minDateData.properties.arrival_time
                    : mode == "flight" &&
                      minDateData.properties.stop_count === 0
                    ? minDateData.properties.arrival_time
                    : mode == "flight" && minDateData.properties.stop_count >= 1
                    ? minDateData.properties?.segments?.[
                        minDateData.properties?.segments?.length - 1
                      ].arrival_time
                    : departureDate
                )}
              </span>
            </div>
          </div>
          {(mode == "flightTrain" ||
            mode == "trainFlight" ||
            mode == "trainTrain") && (
            <>
              <div className="mode-icon">
                <div className="dotted-line"></div>
                <div className="frame-1">
                  <img
                    className="vector-6"
                    src={
                      mode == "trainTrain" || mode == "flightTrain"
                        ? Trainy
                        : Plane
                    }
                  />
                </div>
                <div className="dotted-line-1"></div>
              </div>
              <div className="single-city">
                <div className="city-name-8">
                  <span className="vijayawada-8 capitalize">
                    {mode === "trainFlight"
                      ? minData.flight.properties.destination_city?.toLowerCase()
                      : mode === "trainTrain"
                      ? minData.train2.properties.destination_city?.toLowerCase()
                      : mode === "flightTrain"
                      ? minData.train.properties.destination_city?.toLowerCase()
                      : { toCity }}
                  </span>
                </div>
                <div className="day-date-4">
                  <span className="wed-15-may-6">
                    {formatDate(
                      // this is 3rd
                      mode == "trainFlight"
                        ? minDateData.flight.properties.arrival_time
                        : mode == "flightTrain"
                        ? minDateData.train.properties.arrival_time
                        : minDateData.train2.properties.arrival_time
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="price">
          <div className="view-details">
            <div className="starting-from">
              <span className="starting-from-1">Starting From</span>
            </div>
            <div className="starting-amount">
              <span className="container">
                ₹ {minData.total_price?.toLocaleString()}
              </span>
            </div>
            <div className="starting-adults">
              <div className="container-1">[</div>
              <div className="group-88">
                <img className="icon-1" src={Adcount} />
                <span className="container-3">:</span>
                <div className="container-2">{adults}</div>
              </div>
              <div className="container-4">]</div>
            </div>
          </div>
          <div className="f-2-deets">
            <div className="aprox-timre">
              <span className="approx-time-28-h-10-m">
                Approx Time :{" "}
                {convertDurationToHours(minTimeData.total_duration)}
              </span>
            </div>
            <div className="view-details-btn">
              <button
                className="view-details-txt"
                onClick={() => {
                  // console.log(intermediateData[0].intermediate);
                  handleViewDetails(mode, data[0]?.intermediate ?? "none");
                }}
              >
                <span className="view-details-1">View Details</span>
              </button>
              <div className="bx-right-arrow-alt">
                <img className="vector-3" src={Next} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vector-23"></div>
    </div>
  );
}
