import { useState } from "react";
import TrainIndividual from "./cards/traincard";
import Infocircle from "../assets/vectors/BxInfoCircle_x2.svg";
import People from "../assets/vectors/Vector75_x2.svg";
import { useSelector } from "react-redux";
import { convertToMinutes } from "../utils/misc";

export default function TrainCard({ train, fromCity, toCity, getFares }) {
  const adults = useSelector((state) => state.data.adults);
  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours}h ${remainingMinutes}m`;
  };

  const [selectedPrice, setSelectedPrice] = useState(train.total_price);

  return (
    <div className="card-wrapper">
      <div className="wrapper-top">
        <TrainIndividual
          trainComponent={train}
          fromCity={fromCity}
          toCity={toCity}
          getFares={getFares}
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
        />
      </div>
      <div className="wrapper-bottom">
        <div className="wrapper-duration">
          <span className="journey-duration-11-h">
            Journey Duration :{" "}
            {convertDurationToHours(
              convertToMinutes(train.properties.duration)
            )}
          </span>
        </div>
        <div className="wrapper-price">
          <div className="wrapper-price-text">
            <p className="total-price-15972">
              <span className="total-price-15972-sub-14">Total Price : </span>
              <span>
                {selectedPrice ? "₹" + (selectedPrice * adults) : "Select Train Class"}
              </span>
            </p>
          </div>
          <div className="wrapper-adults">
            <img className="bx-info-circle" src={Infocircle} />
            <div className="adults-count">
              <div className="adults-count-no">
                <span className="container-tra">{adults}</span>
              </div>
              <img className="vector-tra" src={People} />
            </div>
          </div>
        </div>
        <button
          disabled={!selectedPrice}
          className="book-btn disabled:saturate-0 disabled:cursor-not-allowed"
        >
          <span className="book">Book</span>
        </button>
      </div>
    </div>
  );
}
