// import "../styles/landingpage.css";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setOpenlogin } from "../store/dataSlice";

export default function Navbar() {
  const dispatch = useDispatch();
  const handleLogin = () => {
    console.log("clicked.........................");
    dispatch(setOpenlogin(true));
  };

  // const navigate = useNavigate();

  return (
    <div className="navbar">
      <Link to={"/"}>
        <div className="logo">
          <span className="way-port">
            <span>W</span>ay<span>P</span>ort
          </span>
        </div>
      </Link>
      <div className="navbar-opts">
        <span className="login" onClick={handleLogin}>
          Login
        </span>
        <span className="about-us">About Us</span>
      </div>
    </div>
  );
}
