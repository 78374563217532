import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import "../components/loginpage.css";
import { setOpenlogin } from "../store/dataSlice";
import "@dotlottie/player-component";

import DatePicker from "react-multi-date-picker";
import Icon_x2 from "../assets/vectors1/Icon_x2.svg";
import { useSelector, useDispatch } from "react-redux";

import { formatDay } from "../utils/misc";

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function LandingPage34() {
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [fromState, setFromState] = useState("");
  const [toState, setToState] = useState("");
  const [departureDate, setDepartureDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const openlogin = useSelector((state) => state.data.openlogin);

  const [dateSelected, setDateSelected] = useState(false);
  const [fromCitySuggestions, setFromCitySuggestions] = useState([]);
  const [toCitySuggestions, setToCitySuggestions] = useState([]);
  const [adultsOpen, setAdultsOpen] = useState(false);
  const [adults, setAdults] = useState(1);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const clearInput = (type) => {
    if (type === "from") {
      setFromCity("");
      setFromState("");
      setFromCitySuggestions([]);
    } else {
      setToCity("");
      setToState("");
      setToCitySuggestions([]);
    }
  };

  const handleFromCityChange = async (e) => {
    const query = e.target.value;
    setFromCity(query);
    if (query.length > 0) {
      debouncedFetchFromCitySuggestions(query);
      // await fetchCitySuggestions(query, setFromCitySuggestions);
    } else {
      setFromCitySuggestions([]);
    }
  };

  const handleToCityChange = async (e) => {
    const query = e.target.value;
    setToCity(query);
    if (query.length > 0) {
      debouncedFetchToCitySuggestions(query);
      // await fetchCitySuggestions(query, setToCitySuggestions);
    } else {
      setToCitySuggestions([]);
    }
  };

  const handleDateChange = (e) => {
    const formatDate = new Date(e).toISOString().split("T")[0];
    setDepartureDate(formatDate);
    setDateSelected(true);
  };

  //Fetch City Suggestion
  const fetchCitySuggestions = async (query, setSuggestions) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      console.log("calling for", query);
      const response = await fetch("https://wayport.in/cities/cities", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ start_letter: query }),
      });
      console.log(response);
      if (!response) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  const selectCity = (city, type) => {
    if (type === "from") {
      setFromCity(city.city_name);
      setFromState(city.state);
      setFromCitySuggestions([]);
    } else {
      setToCity(city.city_name);
      setToState(city.state);
      setToCitySuggestions([]);
    }
  };
  // Debounced versions of fetchCitySuggestions
  const debouncedFetchFromCitySuggestions = useCallback(
    debounce(
      (query) => fetchCitySuggestions(query, setFromCitySuggestions),
      500
    ),
    []
  );
  const debouncedFetchToCitySuggestions = useCallback(
    debounce((query) => fetchCitySuggestions(query, setToCitySuggestions), 500),
    []
  );

  // const handleFromCityChange = (e) => {
  //     const query = e.target.value;
  //     setFromCity(query);
  //     debouncedFetchFromCitySuggestions(query);
  // };

  // const handleToCityChange = (e) => {
  //     const query = e.target.value;
  //     setToCity(query);
  //     debouncedFetchToCitySuggestions(query);
  // };

  const handleSearch = async () => {
    // const payload = {
    //   // queryStringParameters: {
    //   source_city: fromCity,
    //   desti_city: toCity,
    //   departure_date: departureDate,
    //   adults: adults,
    //   // },
    // };

    try {
      // console.log("Sending payload:", payload);
      // setLoading(true);
      // const response = await fetch(
      //   `https://wayport.in/api/test?source_city=${fromCity}&desti_city=${toCity}&source_state=${fromState}&desti_state=${toState}&departure_date=${departureDate}&adults=${adults}`
      //   // `http://65.1.76.109:4000/routes?source_city=${fromCity}&desti_city=${toCity}&source_state=${fromState}&desti_state=${toState}&departure_date=${departureDate}&adults=${adults}`
      //   //   , {
      //   //   method: "POST",
      //   //   headers: {
      //   //     "Content-Type": "application/json",
      //   //   },
      //   //   body: JSON.stringify(payload),
      //   // }
      // );

      // if (!response.ok) {
      //   setLoading(false);
      //   throw new Error("Network response was not ok");
      // }

      // const data = await response.json();
      // console.log("API response:", data);

      // console.log(data?.results?.train_flight?.length);
      sessionStorage.setItem("searched", true);

      navigate("/modes", {
        state: {
          fromCity,
          fromState,
          toCity,
          toState,
          adults,
          departureDate,
          // flightData: data.results.direct_flight ?? [],
          // trainData: data.results.direct_train ?? [],
          // trainTrainData: data.results.train_train,
          // flightTrainData: data.results.flight_train,
          // trainFlightData: data.results.train_flight,
          // trainFlightTrainData: data.results.train_flight_train,
        },
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setLoading(false);
    }
  };
  const dispatch = useDispatch();

  return (
    <>
      {openlogin && (
        <div className="absolute h-screen w-screen  top-0 left-0 z-30 flex justify-center items-center">
          <button
            className="absolute h-screen w-screen  top-0 left-0 backdrop-blur-sm"
            onClick={() => {
              dispatch(setOpenlogin(false));
            }}
          ></button>
          <div className="logger z-30">
            <input type="text" />
            <button type="sumbit"> login</button>
          </div>
        </div>
      )}

      {/* {loading && (
                <div className="absolute bg-white top-0 left-0 h-screen w-screen flex justify-center items-center z-30 text-4xl">
                    Loading...
                </div>
            )} */}
      <div className="landing-page">
        <div className="main">
          {/* <img className="vector" src={Vector_x2} alt="" /> */}
          <div className="input-container">
            <div className="input-upper">
              <div className="left-container">
                <div className="from-input">
                  <div className="from">
                    <span className="from-1">From</span>
                  </div>
                  <div className="city-name">
                    <div className="city">
                      <input
                        className="vijayawada"
                        value={fromCity}
                        onChange={handleFromCityChange}
                        type="text"
                        placeholder="Departure City"
                      />
                      {fromCity && (
                        <span
                          className="clear-cross"
                          onClick={() => clearInput("from")}
                        >
                          ×
                        </span>
                      )}
                      {fromCitySuggestions.length > 0 && (
                        <div className="suggestions">
                          <div className="top-search-1">Top searches</div>
                          {fromCitySuggestions.map((city, index) => (
                            <div
                              key={index}
                              className="suggestion"
                              onClick={() => selectCity(city, "from")}
                            >
                              {city.city_name}
                              <div className="state-display">
                                {city.state}, India
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {fromState && (
                      <div className="state">
                        {fromCity && (
                          <span className="telangana-india">
                            {fromState}, India
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="from-to">
                  <img className="icon" src={Icon_x2} alt="" />
                </div>
              </div>
              <div className="to-input">
                <div className="from-2">
                  <span className="to">To</span>
                </div>
                <div className="city-name-1">
                  <div className="city-1">
                    <input
                      className="lucknow"
                      value={toCity}
                      onChange={handleToCityChange}
                      type="text"
                      placeholder="Destination City"
                    />
                    {toCity && (
                      <span className="clear-cross" onClick={clearInput}>
                        ×
                      </span>
                    )}
                    {toCitySuggestions.length > 0 && (
                      <div className="suggestions-1">
                        <div className="top-search-2">Top searches</div>
                        {toCitySuggestions.map((city, index) => (
                          <div
                            key={index}
                            className="suggestion-1"
                            onClick={() => selectCity(city, "to")}
                          >
                            {city.city_name}
                            <div className="state-display-1">
                              {city.state}, India
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {toState && (
                    <div className="state-1">
                      {toCity && (
                        <span className="uttar-pradesh-india">
                          {toState}, India
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="departure-input">
                <div className="from-3">
                  <span className="departure">Departure</span>
                </div>
                <div className="city-name-2">
                  <div className="city-2">
                    <div className="dateinput">
                      <DatePicker
                        format="D MMMM YYYY"
                        style={{
                          width: "100%",
                          border: "none",
                          fontFamily: "Poppins",
                          fontSize: 20,
                          padding: 0,
                          letterSpacing: "-0.4px",
                          margin: 0,
                          outline: "none",
                          boxShadow: "none",
                          color: "black",
                          background: "none",
                          cursor: "pointer",
                        }}
                        // range

                        numberOfMonths={2}
                        value={new Date(departureDate)}
                        onChange={handleDateChange}
                        className="teal"
                      />
                    </div>
                  </div>
                  <div className="state-2">
                    <span className="wednesday">
                      {formatDay(departureDate)}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="adults-input"
                onClick={() => {
                  setAdultsOpen(!adultsOpen);
                }}
              >
                <div className="from-4">
                  <span className="travellers">Travellers</span>
                </div>
                <div className="city-name-3">
                  <div className="city-3">
                    <span className="traveller">
                      {adults === 1 ? (
                        <>{adults} Traveller</>
                      ) : (
                        <>{adults} Travellers</>
                      )}
                    </span>
                  </div>
                  <div className="state-3">
                    <span className="adult">
                      {adults === 1 ? (
                        <>{adults} Adult</>
                      ) : (
                        <>{adults} Adults</>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {adultsOpen && (
                <div className="adult-pop">
                  <div className="containerx">
                    <p className="title">Adults</p>
                    <div className="button-container">
                      <button
                        onClick={() => {
                          setAdults(adults > 0 ? adults - 1 : 0); // Ensure adults don't go negative
                        }}
                        className="button-top"
                      >
                        -
                      </button>
                      <p className="adult-count">
                        {adults.toString().padStart(2, "0")}
                      </p>
                      <button
                        onClick={() => {
                          setAdults(adults + 1);
                        }}
                        className="button-top"
                      >
                        +
                      </button>
                      <p className="adult-info">16+ years</p>
                    </div>

                    {/*<p className="notice notice-padding-bottom">
                      Your age at time of travel must be valid for the age
                      category booked. Airlines have restrictions on under 18s
                      travelling alone.
                    </p>
                    <p className="notice notice-padding-top">
                      Age limits and policies for travelling with children may
                      vary so please check with the airline before booking.
                    </p>*/}

                    <div className="footer">
                      <button
                        onClick={() => {
                          setAdultsOpen(!adultsOpen);
                        }}
                        className="done-button"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="btn-wrap">
              <div className="just-type-in-city-name">
                Just type in{" "}
                <span className="just-type-in-city-name-sub3">City</span> name.
              </div>
              <div className="search-button" onClick={handleSearch}>
                <span className="search">Search</span>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="w-[1023px]">
              <div className="skeleton w-[300px] min-w-[300px] min-h-[28px] mb-11 h-[32px]"></div>

              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <div
                    key={i}
                    className="skeleton w-full min-h-[80px] mb-10 h-[80px]"
                  ></div>
                ))}
            </div>
          ) : (
            <div style={{ marginTop: 30 }}>
              <dotlottie-player
                background="#f2f3f4"
                direction="1"
                id="animation_map-with-pulsing-city-dots_e16d5fea-1165-11ee-8615-6bd07bc9a3fb"
                speed="1"
                mode="normal"
                src="https://lottie.host/74f35a04-2958-4988-8787-8f6ba5a1db9c/JTFU5zKlX4.json"
                style={{
                  transform: "scale(1)",
                  width: "1190px",
                  height: "auto",
                }}
                autoplay
                loop
              ></dotlottie-player>
              <div class="glowing-dot"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
