import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { ReactComponent as Plane } from "../assets/images/flight.png";
import Infocircle from "../assets/vectors/BxInfoCircle_x2.svg";
import People from "../assets/vectors/Vector75_x2.svg";
import Buffer from "../assets/vectors/BxInfoCircle9_x2.svg";
import FlightIndividual from "./cards/flightcard";
import FlightFlightcard from "./cards/flightFlightcard";
import UpArrow from "../assets/images/up_arrow.svg";
import DownArrow from "../assets/images/down_arrow.svg";
import Plane_V from "../assets/vectors1/Plane_V.svg";
import AkasaAir from "../assets/flightnormal/akasaair.png";
import Star_air from "../assets/images/startAir.png";

import IndigoS from "../assets/flightsmall/indigo.png";
import AirIndiaS from "../assets/flightsmall/airindia.png";
import AirIndiaExpressS from "../assets/flightsmall/airindiaexpress.png";
import VistaraS from "../assets/flightsmall/vistara.png";
import SpicejetS from "../assets/flightsmall/spicejet.png";
import AkasaAirS from "../assets/flightsmall/akasaair.png";
import StarAirS from "../assets/flightsmall/starair.png";

import Indigo from "../assets/flightnormal/indigo.png";
import AirIndia from "../assets/flightnormal/airindia.png";
import AirIndiaExpress from "../assets/flightnormal/airindiaexpress.png";
import Vistara from "../assets/flightnormal/vistara.png";
import Spicejet from "../assets/flightnormal/spicejet.png";

export default function FlightCard({ flight }) {
  const adults = useSelector((state) => state.data.adults);

  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };
  const [closecard, setClosecard] = useState(false);

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate) => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime) => {
    return timeFormat.format(new Date(inputtime));
  };

  const imageselector = (inputcomp) => {
    if (inputcomp === "indiGo") {
      return Indigo;
    } else if (inputcomp === "vistara") {
      return Vistara;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpress;
    } else if (inputcomp === "air india") {
      return AirIndia;
    } else if (inputcomp === "akasa air") {
      return AkasaAir;
    } else if (inputcomp === "star air") {
      return Star_air;
    } else if (inputcomp === "spicejet") {
      return Spicejet;
    }
  };

  const imageselectorS = (inputcomp) => {
    if (inputcomp === "indigo") {
      return IndigoS;
    } else if (inputcomp === "vistara") {
      return VistaraS;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpressS;
    } else if (inputcomp === "air india") {
      return AirIndiaS;
    } else if (inputcomp === "akasa air") {
      return AkasaAirS;
    } else if (inputcomp === "starair") {
      return StarAirS;
    } else if (inputcomp === "spicejet") {
      return SpicejetS;
    }
  };
  const flightname = (inputcomp) => {
    if (inputcomp === "IndiGo") {
      return "6E";
    } else if (inputcomp === "Vistara") {
      return "UK";
    } else if (inputcomp === "Air India Express") {
      return "IX";
    } else if (inputcomp === "Air India") {
      return "AI";
    } else if (inputcomp === "Akasa Air") {
      return "QP";
    } else if (inputcomp === "Start Air") {
      return "S5";
    }
  };

  return (
    <>
      {!flight.properties.stop_count ? (
        <>
          <div className="card-wrapper" style={{ padding: 0 }}>
            <div className="wrapper-top">
              <FlightIndividual flightComponent={flight.properties} />
            </div>
            <div className="wrapper-bottom">
              <div className="wrapper-duration">
                <span className="journey-duration-11-h">
                  Journey Duration :{" "}
                  {convertDurationToHours(flight.properties.duration)}
                </span>
              </div>
              <div className="wrapper-price">
                <div className="wrapper-price-text">
                  <p className="total-price-15972">
                    <span className="total-price-15972-sub-14">
                      Total Price :
                    </span>
                    <span>₹{flight.properties.price}</span>
                  </p>
                </div>
                <div className="wrapper-adults">
                  <img className="bx-info-circle" src={Infocircle} />
                  <div className="adults-count">
                    <div className="adults-count-no">
                      <span className="container-tra">{adults}</span>
                    </div>
                    <img className="vector-tra" src={People} />
                  </div>
                </div>
              </div>
              <div className="book-btn">
                <span className="book">Book</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {closecard && (
              <>
                <div className="upper-palet">
                  <div className="inner-palet-1">
                    <div className="timings" style={{ width: 94 }}>
                      <div className="datentime">
                        <div className="date">
                          <span className="wed-15-may">
                            {formatDate(
                              flight.properties.segments[0].departure_time
                            )}
                          </span>
                        </div>
                        <div className="time">
                          <span className="container">
                            {formatTime(
                              flight.properties.segments[0].departure_time
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="dotted-line">
                        <div className="vector-18-x"></div>
                      </div>
                      <div className="datentime">
                        <div className="date">
                          <span className="wed-15-may-1">
                            {formatDate(
                              flight.properties.segments[
                                flight.properties.stop_count
                              ].arrival_time
                            )}
                          </span>
                        </div>
                        <div className="time">
                          <span className="container-1">
                            {formatTime(
                              flight.properties.segments[
                                flight.properties.stop_count
                              ].arrival_time
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flight-vector"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="flight-icon">
                        <img className="flight" src={Plane_V} />
                      </div>
                      <div
                        className="vector-17-xyz"
                        style={{ marginTop: 6.4, marginRight: 0 }}
                      ></div>
                      <div
                        className="vector-16-xyz"
                        style={{ marginBottom: 6.4, marginRight: 0 }}
                      ></div>
                      <div className="flight-icon">
                        <img className="flight" src={Plane_V} />
                      </div>
                    </div>
                    <div className="stoppage">
                      <div className="flight-type-x">
                        <div className="flex" style={{ alignItems: "center" }}>
                          <div
                            className="flight-image-x"
                            style={{ width: 60, height: 20 }}
                          >
                            {/* <div className="image-21-x"> */}
                            <img
                              style={{
                                objectFit: "contain",
                                height: 19,
                                width: 59,
                              }}
                              src={imageselectorS(
                                flight.properties.segments[0].flight_name?.toLowerCase()
                              )}
                            />
                            {/* </div> */}
                          </div>
                          <div className="flight-no">
                            <span className="non-stop">
                              {flightname(
                                flight.properties.segments[0].flight_name?.toLowerCase()
                              )}{" "}
                              {flight.properties.segments[0].flight_number}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stops" style={{ paddingTop: 9 }}>
                        <span className="non-stop-1">
                          {flight.properties.stop_count + " Stop "}
                          at{" "}
                          {flight.properties.segments.map((segment, i) => (
                            <span key={i}>
                              {segment.layover_time ? segment.origin.city : ""}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="flight-type-x">
                        <div className="flex" style={{ alignItems: "center" }}>
                          <div
                            className="flight-image-x"
                            style={{ width: 60, height: 20 }}
                          >
                            {/* <div className="image-21-x" > */}
                            <img
                              style={{
                                objectFit: "contain",
                                height: 19,
                                width: 59,
                              }}
                              src={imageselectorS(
                                flight.properties.segments[1].flight_name?.toLowerCase()
                              )}
                            />
                            {/* </div> */}
                          </div>
                          <div className="flight-no">
                            <span className="non-stop">
                              {flightname(
                                flight.properties.segments[1].flight_name?.toLowerCase()
                              )}{" "}
                              {flight.properties.segments[1].flight_number}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flight-price"
                      style={{ justifySelf: "flex-end" }}
                    >
                      <div className="price">
                        <span className="container-2">
                          ₹{" "}
                          {Math.round(
                            flight.properties.price / parseInt(adults)
                          )}
                        </span>
                      </div>
                      <div className="individual">
                        <p className="per-individual">
                          <span className="per-individual-sub-1">
                            Per Individual
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="close-btn" onClick={() => setClosecard(false)}>
                  <div className="closer-text">Close</div>
                  <div className="closer-arrow">
                    <img src={UpArrow} alt="" />
                  </div>
                </div>
              </>
            )}

            <div
              className="card-wrapper"
              style={{
                paddingTop: 16,
              }}
            >
              {!closecard && (
                <>
                  <div
                    className="wrapper-top"
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <FlightFlightcard ffCompenent={flight.properties} />
                  </div>
                </>
              )}

              <>
                {flight.properties.segments.map((segment, i) => (
                  <div
                    key={i}
                    className="w-full"
                    style={{
                      height: closecard ? (i == 0 ? 192 : 236) : 0,
                      overflow: "hidden",
                      paddingLeft: 0,
                      transition: "all 0.3s ease-in-out",
                    }}
                  >
                    {segment.layover_time ? (
                      <div
                        className="wrapper-top-0"
                        style={{ marginLeft: -11 }}
                      >
                        <div className="frame-5532">
                          <div className="frame-2378">
                            <div className="vector-1816"></div>
                          </div>
                          <div className="frame-2512">
                            <span className="h-5">
                              {convertDurationToHours(segment.layover_time)}
                            </span>
                          </div>
                          <div className="frame-2388">
                            <div className="vector-1817"></div>
                          </div>
                        </div>
                        <div className="buffer-2">
                          <img className="bx-info-circle-6" src={Buffer} />
                          <div className="stops-5-ff font-primary">
                            <span className="non-stop-2-ff">
                              Connect in Airport
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className="wrapper-top"
                      style={{ padding: 0, marginLeft: -10 }}
                    >
                      <FlightIndividual flightComponent={segment} />
                    </div>
                  </div>
                ))}
              </>

              <div className="wrapper-bottom" style={{ marginTop: 16 }}>
                <div className="wrapper-duration">
                  <span className="journey-duration-11-h">
                    Journey Duration :{" "}
                    {convertDurationToHours(flight.properties.total_duration)}
                  </span>
                </div>
                <div className="wrapper-price">
                  <div className="wrapper-price-text">
                    <p className="total-price-15972">
                      <span className="total-price-15972-sub-14">
                        Total Price :
                      </span>
                      <span> ₹ {flight.total_price}</span>
                    </p>
                  </div>
                  <div className="wrapper-adults">
                    <img className="bx-info-circle" src={Infocircle} />
                    <div className="adults-count">
                      <div className="adults-count-no">
                        <span className="container-tra">{adults}</span>
                      </div>
                      <img className="vector-tra" src={People} />
                    </div>
                  </div>
                </div>
                <div className="book-btn">
                  <span className="book">Book</span>
                </div>
              </div>
              {!closecard && (
                <div className="seton" onClick={() => setClosecard(1)}>
                  <div className="seton-text">View Details</div>
                  <div className="seton-arrow">
                    <img src={DownArrow} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
