import Indigo from "../../assets/flightnormal/indigo.png";
import AirIndia from "../../assets/flightnormal/airindia.png";
import AirIndiaExpress from "../../assets/flightnormal/airindiaexpress.png";
import Vistara from "../../assets/flightnormal/vistara.png";
import Spicejet from "../../assets/flightnormal/spicejet.png";
import AkasaAir from "../../assets/flightnormal/akasaair.png";
import Star_air from "../../assets/flightnormal/starair.png";

import { useSelector } from "react-redux";

import Plane_V from "../../assets/vectors1/Plane_V.svg";

function FlightFlightcard({ ffCompenent }) {
  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate) => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime) => {
    return timeFormat.format(new Date(inputtime));
  };

  const imageselector = (inputcomp) => {
    if (inputcomp === "indigo") {
      return Indigo;
    } else if (inputcomp === "vistara") {
      return Vistara;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpress;
    } else if (inputcomp === "air india") {
      return AirIndia;
    } else if (inputcomp === "akasa air") {
      return AkasaAir;
    } else if (inputcomp === "starair") {
      return Star_air;
    } else if (inputcomp === "spicejet") {
      return Spicejet;
    }
  };
  const adults = useSelector((state) => state.data.adults);

  const flightname = (inputcomp) => {
    if (inputcomp === "indigo") {
      return "6E";
    } else if (inputcomp === "vistara") {
      return "UK";
    } else if (inputcomp === "air india express") {
      return "IX";
    } else if (inputcomp === "air india") {
      return "AI";
    } else if (inputcomp === "akasa air") {
      return "QP";
    } else if (inputcomp === "starair") {
      return "S5";
    }
  };

  const n = ffCompenent.segments.length;

  return (
    <>
      <div className="flight-card" style={{ width: 684 }}>
        <div className="container">
          <div className="timings">
            <div className="datentime">
              <div className="date">
                <span className="wed-15-may">
                  {formatDate(ffCompenent.segments[0].departure_time)}
                </span>
              </div>
              <div className="time">
                <span className="container">
                  {formatTime(ffCompenent.segments[0].departure_time)}
                </span>
              </div>
            </div>
            <div className="dotted-line">
              <div className="vector-18-x"></div>
            </div>
            <div className="duration">
              <span className="h-55-m" style={{ whiteSpace: "nowrap" }}>
                {convertDurationToHours(
                  (ffCompenent.segments[n - 1].arrival_time -
                    ffCompenent.segments[0].departure_time) /
                    60000
                )}
              </span>
            </div>
            <div className="dotted-line">
              <div className="vector-18-x"></div>
            </div>
            <div className="datentime">
              <div className="date">
                <span className="wed-15-may-1">
                  {formatDate(ffCompenent.segments[n - 1].arrival_time)}
                </span>
              </div>
              <div className="time">
                <span className="container-1">
                  {formatTime(ffCompenent.segments[n - 1].arrival_time)}
                </span>
              </div>
            </div>
          </div>
          <div className="flight-vector">
            <div className="vector-16-xy"></div>
            <div className="flight-icon">
              <img className="flight" src={Plane_V} />
            </div>
            <div className="vector-17-xyz"></div>
            <div className="vector-16-xyz"></div>
            <div className="flight-icon">
              <img className="flight" src={Plane_V} />
            </div>
            <div className="vector-17-xy"></div>
          </div>
          <div className="flight-details">
            <div className="airport-name">
              <p className="airport-name-2">
                <span className="airport-name-2-sub-2">
                  {ffCompenent.segments[0].origin.name},{" "}
                  {ffCompenent.segments[0].origin.city}
                  {ffCompenent.segments[0].origin.terminal && (
                    <span> [T- {ffCompenent.segments[0].origin.terminal}]</span>
                  )}
                </span>
              </p>
            </div>
            <div className="flight-more-details-x">
              <div className="stops">
                <span className="non-stop-1">
                  {ffCompenent.stop_count + " Stop "}
                  at{" "}
                  {ffCompenent.segments.map((segment, i) => (
                    <span key={i}>
                      {segment.layover_time ? segment.origin.city : ""}
                    </span>
                  ))}
                </span>
              </div>
              <div className="flight-type-x">
                {ffCompenent.segments.map((segment, i) => (
                  <div className="flex gap-1" key={i}>
                    <div className="flight-image-x">
                      {/* <div className="image-21-x"> */}
                      <img
                        style={{ objectFit: "contain" }}
                        src={imageselector(segment.flight_name?.toLowerCase())}
                      />
                      {/* </div> */}
                    </div>
                    <div className="flight-no">
                      <span className="non-stop">
                        {flightname(segment.flight_name?.toLowerCase())}{" "}
                        {segment.flight_number}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flight-price">
                <div className="price">
                  <span className="container-2">
                    ₹ {Math.round(ffCompenent.price / parseInt(adults))}
                  </span>
                </div>
                <div className="individual">
                  <p className="per-individual">
                    <span className="per-individual-sub-1">Per Individual</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="airport-name-1">
              <p className="airport-name-3">
                <span className="airport-name-3-sub-0">
                  {ffCompenent.segments[n - 1].destination.name},{" "}
                  {ffCompenent.segments[n - 1].destination.city}
                  {ffCompenent.segments[n - 1].destination.terminal && (
                    <span>
                      {" "}
                      [T- {ffCompenent.segments[n - 1].destination.terminal}]
                    </span>
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlightFlightcard;
