import Trainy from "../assets/vectors1/Trainy.svg";
import Plane from "../assets/vectors1/Plane.svg";
import Adcount from "../assets/vectors1/Adcount.svg";
import Next from "../assets/vectors/Vector9_x2.svg";

export default function ModeCardstft({
  mode,
  index,
  data,
  fromCity,
  midCity,
  toCity,
  departureDate,
  adults,
  handleViewDetails,
}) {
  const findMinPrice = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        currentItem.total_price < minItem.total_price ? currentItem : minItem
      )
    );
  };

  const findMinTime = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        currentItem.total_duration < minItem.total_duration
          ? currentItem
          : minItem
      )
    );
  };

  const minDate = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        currentItem.flight.properties.arrival_time <
          minItem.flight.properties.arrival_time
          ? currentItem
          : minItem
      )
    );
  };

  const minDate1 = (data) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        currentItem.train2.properties.arrival_time <
          minItem.train2.properties.arrival_time
          ? currentItem
          : minItem
      )
    );
  };

  const midCity1 = midCity.substring(0, midCity.indexOf("&"));
  const midCity2 = midCity.substring(midCity.indexOf("&") + 1);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.toLocaleDateString("en-US", { weekday: "short" });
    const month = date.toLocaleString("default", { month: "short" });

    const dayOfMonth = date.getDate();
    const year = date.getFullYear();

    const nth = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}, ${dayOfMonth}${nth(dayOfMonth)} ${month}`;
  };

  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const minData = findMinPrice(data);
  const minTimeData = findMinTime(data);
  const minDateData = minDate(data);
  const minDateData1 = minDate1(data);

  return (
    <div key={index} className="f-2">
      <div
        className="flight-details"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="mode-slicer">
          <div className="mode-main">
            <div className="single-city">
              <div className="city-name-8">
                <span className="vijayawada-6 capitalize">
                  {minData.train1.properties.origin_city?.toLowerCase()}
                
                </span>
              </div>
              <div className="day-date-4">
                <span className="wed-15-may-4">
                  {formatDate(departureDate)}
                </span>
              </div>
            </div>
            <div className="mode-icon">
              <div className="dotted-line"></div>
              <div className="frame-1">
                <img className="vector-6" src={Trainy} />
              </div>
              <div className="dotted-line-1"></div>
            </div>
            <div className="single-city">
              <div className="city-name-8">
                <span className="vijayawada-7 capitalize">
                  {midCity1?.toLowerCase()}
                </span>
              </div>
              <div className="day-date-4">
                <span className="wed-15-may-5">
                  {formatDate(departureDate)}
                </span>
              </div>
            </div>

            <div className="mode-icon">
              <div className="dotted-line"></div>
              <div className="frame-1">
                <img className="vector-6" src={Plane} />
              </div>
              <div className="dotted-line-1"></div>
            </div>
            <div className="single-city">
              <div className="city-name-8">
                <span className="vijayawada-7 capitalize">
                  {midCity2?.toLowerCase()}
                </span>
              </div>
              <div className="day-date-4">
                <span className="wed-15-may-5">
                  {formatDate(minDateData1.train2.properties.departure_time)}
                </span>
              </div>
            </div>

            <div className="mode-icon">
              <div className="dotted-line"></div>
              <div className="frame-1">
                <img className="vector-6" src={Trainy} />
              </div>
              <div className="dotted-line-1"></div>
            </div>
            <div className="single-city">
              <div className="city-name-8">
                <span className="vijayawada-8 capitalize">{minData.train2.properties.destination_city?.toLowerCase()}</span>
              </div>
              <div className="day-date-4">
                <span className="wed-15-may-6">
                  {formatDate(minDateData.train2.properties.arrival_time)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="divider-tft"></div>
        <div className="price-tft">
          <div className="view-details">
            <div className="starting-from">
              <span className="starting-from-1">Starting From</span>
            </div>
            <div className="starting-amount">
              <span className="container">
                ₹ {minData.total_price?.toLocaleString()}
              </span>
            </div>
            <div className="starting-adults">
              <div className="container-1">[</div>
              <div className="group-88">
                <img className="icon-1" src={Adcount} />
                <span className="container-3">:</span>
                <div className="container-2">{adults}</div>
              </div>
              <div className="container-4">]</div>
            </div>
          </div>
          <div className="f-2-deets">
            <div className="aprox-timre">
              <span className="approx-time-28-h-10-m">
                Approx Time :{" "}
                {convertDurationToHours(minTimeData.total_duration)}
              </span>
            </div>
            <div className="view-details-btn">
              <button
                className="view-details-txt"
                onClick={() => {
                  // console.log(intermediateData[0].intermediate);
                  handleViewDetails(
                    mode,
                    data[0]?.intermediate1 + "&" + data[0]?.intermediate2 ??
                    "none"
                  );
                }}
              >
                <span className="view-details-1">View Details</span>
              </button>
              <div className="bx-right-arrow-alt">
                <img className="vector-3" src={Next} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vector-23"></div>
    </div>
  );
}
