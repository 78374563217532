import Indigo from "../../assets/flightnormal/indigo.png";
import AirIndia from "../../assets/flightnormal/airindia.png";
import AirIndiaExpress from "../../assets/flightnormal/airindiaexpress.png";
import Vistara from "../../assets/flightnormal/vistara.png";
import StarAir from "../../assets/flightnormal/starair.png";
import Spicejet from "../../assets/flightnormal/spicejet.png";
import AkasaAir from "../../assets/flightnormal/akasaair.png";

import Plane_V from "../../assets/vectors1/Plane_V.svg";
import { useSelector } from "react-redux";

function FlightIndividual({ flightComponent }) {
  const convertDurationToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate) => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime) => {
    return timeFormat.format(new Date(inputtime));
  };

  const imageselector = (inputcomp) => {
    if (inputcomp === "indigo") {
      return Indigo;
    } else if (inputcomp === "vistara") {
      return Vistara;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpress;
    } else if (inputcomp === "air india") {
      return AirIndia;
    } else if (inputcomp === "akasa air") {
      return AkasaAir;
    } else if (inputcomp === "starair") {
      return StarAir;
    } else if (inputcomp === "spicejet") {
      return Spicejet;
    }
  };

  const flightname = (inputcomp) => {
    if (inputcomp === "IndiGo") {
      return "6E";
    } else if (inputcomp === "Vistara") {
      return "UK";
    } else if (inputcomp === "Air India Express") {
      return "IX";
    } else if (inputcomp === "Air India") {
      return "AI";
    } else if (inputcomp === "Akasa Air") {
      return "QP";
    } else if (inputcomp === "StarAir") {
      return "S5";
    }
  };

  const adults = useSelector((state) => state.data.adults);

  return (
    <div className="flight-card">
      <div className="container">
        <div className="timings">
          <div className="datentime">
            <div className="date">
              <span className="wed-15-may">
                {formatDate(flightComponent.departure_time)}
              </span>
            </div>
            <div className="time">
              <span className="container">
                {formatTime(flightComponent.departure_time)}
              </span>
            </div>
          </div>
          <div className="dotted-line">
            <div className="vector-18-x"></div>
          </div>
          <div className="duration">
            <span className="h-55-m">
              {convertDurationToHours(flightComponent.duration)}
            </span>
          </div>
          <div className="dotted-line">
            <div className="vector-18-x"></div>
          </div>
          <div className="datentime">
            <div className="date">
              <span className="wed-15-may-1">
                {formatDate(flightComponent.arrival_time)}
              </span>
            </div>
            <div className="time">
              <span className="container-1">
                {formatTime(flightComponent.arrival_time)}
              </span>
            </div>
          </div>
        </div>
        <div className="flight-vector">
          <div className="vector-16-x"></div>
          <div className="flight-icon">
            <img className="flight" src={Plane_V} />
          </div>
          <div className="vector-17-x"></div>
        </div>
        <div className="flight-details">
          <div className="airport-name">
            <p className="airport-name-2">
              <span className="airport-name-2-sub-2">
                {flightComponent.origin.name}, {flightComponent.origin.city}
                {flightComponent.origin.terminal && (
                  <span> [T- {flightComponent.origin.terminal}]</span>
                )}
              </span>
            </p>
          </div>
          <div className="flight-more-details">
            <div className="flight-type">
              <div className="flight-image">
                {/* <div className="image-21"> */}
                <img
                  style={{ objectFit: "contain" }}
                  src={imageselector(
                    flightComponent.flight_name?.toLowerCase()
                  )}
                />
                {/* </div> */}
              </div>
              <div className="flight-no">
                <span className="non-stop">
                  {flightname(flightComponent.flight_name)}{" "}
                  {flightComponent.flight_number}
                </span>
              </div>
            </div>
            <div className="stops">
              <span className="non-stop-1">Non Stop</span>
            </div>

            {flightComponent.stop_count === 0 ? (
              <div className="flight-price">
                <div className="price">
                  <span className="container-2">
                    ₹ {Math.round(flightComponent.price / parseInt(adults))}
                  </span>
                </div>
                <div className="individual">
                  <p className="per-individual">
                    <span className="per-individual-sub-1">Per Individual</span>
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="airport-name-1">
            <p className="airport-name-3">
              <span className="airport-name-3-sub-0">
                {flightComponent.destination.name},{" "}
                {flightComponent.destination.city}
                {flightComponent.destination.terminal && (
                  <span> [T- {flightComponent.destination.terminal}]</span>
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightIndividual;
